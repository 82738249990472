export const ACTIONS = {
  add: 'add',
  edit: 'edit',
  view: 'view',
  draft: 'draft'
}

export const STATUSES = {
  open: 'open',
  draft: 'draft',
  processing: 'processing',
  closed: 'closed'
}
