
import React from 'react'
import PricingZoneList from '../../components/modules/master/pricingZones/list'
import PricingZoneAddEdit from '../../components/modules/master/pricingZones/addEdit'

export const PRICING_ZONES_LISTING_LINK = '/pricing-zones/list'
export const PRICING_ZONES_ADD_LINK = '/pricing-zones/add'
export const PRICING_ZONES_EDIT_LINK = '/pricing-zones/edit'

const PricingZonesRoutes = [
  {
    path: PRICING_ZONES_LISTING_LINK,
    pageTitle: 'Pricing Zones',
    icon: 'dollar sign',
    isMenu: true,
    menuMatchingRoutes: [PRICING_ZONES_LISTING_LINK, PRICING_ZONES_ADD_LINK],
    exact: true,
    breadCrumbs: [
      {
        name: 'Pricing Zones'
      }
    ],
    component: (props) => {
      return (
        <PricingZoneList {...props} />
      )
    }
  },
  {
    path: PRICING_ZONES_ADD_LINK,
    pageTitle: 'Add Pricing Zone',
    icon: 'map marker alternate',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Pricing Zones',
        path: PRICING_ZONES_LISTING_LINK
      },
      {
        name: 'Add'
      }
    ],
    exact: true,
    component: (props) => {
      return (
        <PricingZoneAddEdit {...props} />
      )
    }
  },
  {
    path: PRICING_ZONES_EDIT_LINK + '/:id',
    pageTitle: 'Edit Pricing Zone',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Pricing Zones',
        path: PRICING_ZONES_LISTING_LINK
      },
      {
        name: 'Edit'
      }
    ],
    exact: true,
    component: (props) => {
      return (
        <PricingZoneAddEdit {...props} />
      )
    }
  }
]

export default PricingZonesRoutes
