import React from 'react'
import { Link } from 'react-router-dom'

export const NotFoundException = 'NotFoundException'
export const withErrorHandler = WrappedComponent => {
  return class extends React.Component {
    state = {
      isModalOpen: false,
      header: '',
      content: ''
    }

    handleError = error => {
      console.log(error.message)
      if (error.message === NotFoundException) {
        this.handleNotFoundException()
        return
      }

      this.handleInternalServerException(error)
    }

    handleNotFoundException = () => {
      console.log(this.props)
      this.props.setModalInfo(
        <h3>404 - Resource Found</h3>,
        <p>
          We weren't able to find any data for the resource you requested,
          please check the url.
          <br />
          <Link to='/'>Got to Homepage</Link>
        </p>
      )
      this.props.openModal()
    }

    handleInternalServerException = err => {
      console.log(this.props)
      this.props.setModalInfo(
        <h3>500 - Ooops! something went wrong</h3>,
        <>
          <p>
            Pass this info to the cool team of trained programming monkeys to
            fix it as soon as possible please:
          </p>
          <br />
          {JSON.stringify(err, null, 2)}
          <Link to='/'>Got to Homepage</Link>
        </>
      )
      this.props.openModal()
    }

    render () {
      return <WrappedComponent handleError={this.handleError} {...this.props} />
    }
  }
}
