import React from 'react'
import VendorPOAddEdit from '../../components/modules/location/replenishment/VendorPOAddEdit'
import VendorDirectPOList from '../../components/modules/location/items/list/VendorDirectPOList'

export const IN_LOCATION_VENDOR_PO_ROOT =
  '/in-location/:locationId/vendor-po'
export const IN_LOCATION_VENDOR_PO_LINK =
  IN_LOCATION_VENDOR_PO_ROOT + '/list'
export const IN_LOCATION_VENDOR_PO_ADD =
  IN_LOCATION_VENDOR_PO_ROOT + '/add'
export const IN_LOCATION_WAREHOUSE_PO_VIEW =
  IN_LOCATION_VENDOR_PO_ROOT + '/view/:poId'
export const IN_LOCATION_VENDOR_PO =
  IN_LOCATION_VENDOR_PO_ROOT + '/:action(add|edit|draft|view)/:poId?'

const VendorPORoutes = [
  {
    path: IN_LOCATION_VENDOR_PO_LINK,
    pageTitle: "Vendor Direct PO's",
    isLocationMenu: true,
    breadCrumbs: [{ name: 'Vendor Direct PO\'s List' }],
    exact: true,
    component: props => <VendorDirectPOList {...props} />
  },
  {
    path: IN_LOCATION_VENDOR_PO,
    pageTitle: 'Purchase Order',
    icon: 'align center',
    isLocationMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Purchase Order'
      }
    ],
    exact: true,
    component: props => <VendorPOAddEdit {...props} />
  }
]

export default VendorPORoutes
