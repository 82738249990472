import Amplify from 'aws-amplify'
import React from 'react'
import 'semantic-ui-less/semantic.less'
import amplifyConfig from './amplifyConfig'
import AppContainer from './containers/appContainer'
import withCustomAuthenticator from './hoc/withCustomAuthenticator'

Amplify.configure(amplifyConfig)

class App extends React.Component {
  render () {
    return <AppContainer />
  }
}

export default withCustomAuthenticator(App)
