import { API, Auth } from 'aws-amplify'

class AmplifyHttpRequest {
  #apiName = undefined

  /**
   *
   * @param {String} _apiName
   */
  constructor (_apiName) {
    this.#apiName = _apiName
  }

  /**
   * @returns {String}
   */
  getApiName () {
    return this.#apiName
  }

  async getConfiguration (configs, payload = {}) {
    // const user = await Auth.currentAuthenticatedUser()
    // const token = user.signInUserSession.idToken.jwtToken
    return { ...configs, body: payload }
  }

  /**
   *
   * @param {String} endpoint
   * @param {Object} configs
   */
  async get (endpoint, configs = {}) {
    return API.get(
      this.#apiName,
      endpoint,
      await this.getConfiguration(configs)
    )
  }

  /**
   *
   * @param {String} endpoint
   * @param {Object} payload
   * @param {Object} configs
   */
  async post (endpoint, payload = {}, configs = {}) {
    return API.post(
      this.#apiName,
      endpoint,
      await this.getConfiguration(configs, payload)
    )
  }

  /**
   *
   * @param {String} _apiName api name
   * @returns {AmplifyHttpRequest}
   */
  static createInstance (_apiName) {
    return new this.prototype.constructor(_apiName)
  }
}

export default AmplifyHttpRequest
