import React from 'react'
import ProductZoneAddEdit from '../../components/modules/master/productZones/addEdit/ProductZoneAddEdit'
import ProductZoneList from '../../components/modules/master/productZones/list/ProductZoneList'

export const PRODUCT_ZONES_LISTING_LINK = '/product-zones'
export const PRODUCT_ZONES_ADD_LINK = '/product-zones/add'
export const PRODUCT_ZONES_EDIT_LINK = '/product-zones/edit'

const ProductZonesRoutes = [
  {
    path: PRODUCT_ZONES_LISTING_LINK,
    pageTitle: 'Product Zones',
    icon: 'tags',
    isMenu: true,
    menuMatchingRoutes: [PRODUCT_ZONES_LISTING_LINK, PRODUCT_ZONES_ADD_LINK],
    exact: true,
    breadCrumbs: [
      {
        name: 'Product Zones'
      }
    ],
    component: props => {
      return <ProductZoneList {...props} />
    }
  },
  {
    path: PRODUCT_ZONES_ADD_LINK,
    pageTitle: 'Add Product Zone',
    icon: 'tags',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Product Zones',
        path: PRODUCT_ZONES_LISTING_LINK
      },
      {
        name: 'Add'
      }
    ],
    exact: true,
    component: props => {
      return <ProductZoneAddEdit {...props} />
    }
  },
  {
    path: PRODUCT_ZONES_EDIT_LINK + '/:id',
    pageTitle: 'Edit Product Zone',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Product Zone',
        path: PRODUCT_ZONES_LISTING_LINK
      },
      {
        name: 'Edit'
      }
    ],
    exact: true,
    component: props => {
      return <ProductZoneAddEdit {...props} />
    }
  }
]

export default ProductZonesRoutes
