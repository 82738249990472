import RequestHandler, { stringifyQueryParams } from './HttpRequestsHandler'
const basePath = '/location-types'

const filterBy = params => {
  return RequestHandler.get(`${basePath}${stringifyQueryParams(params)}`)
}

const getAll = () => {
  return RequestHandler.get(basePath)
}

const get = id => {
  return RequestHandler.get(`${basePath}/${id}`)
}

const save = locationType => {
  return RequestHandler.post(basePath, locationType)
}

export default {
  filterBy,
  get,
  getAll,
  save
}
