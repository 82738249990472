import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useMarshallResponse from '../../../../../hooks/useMarshallResponse'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  ENTITY_PREFIX_LOCATION,
  PAGE_SIZE
} from '../../../../../repositories/variablesRepository'
import { IN_LOCATION_PRODUCT_EDIT_LINK } from '../../../../../routes/locationLayoutRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb/BreadCrumb'
import CustomSimpleCellFormatter from '../../../../common/list/CustomSimpleCellFormatter'
import { ItemList } from '../../../../common/list/ItemList'

/**
 * @typedef {import('../../../../../types/grid-column-config').GridColumnConfig} GridColumnConfig
 * @typedef {import('./location-item-list-row').LocationItemListRow} LocationItemListRow
 */

const editBtnKey = 'editBtn'
/** @type {Array<GridColumnConfig>} */
const gridModel = [
  {
    key: 'productNumber',
    name: 'Item #',
    width: 90,
    resizable: true,
    serverResponseKey: 'PK'
  },
  {
    key: 'productName',
    name: 'Name',
    resizable: true,
    serverResponseKey: 'productName'
  },
  {
    key: 'brand',
    name: 'Brand',
    width: 200,
    resizable: true,
    serverResponseKey: 'brand'
  },
  {
    key: 'size',
    name: 'Size',
    width: 75,
    resizable: true,
    serverResponseKey: 'size'
  },
  {
    key: 'form',
    name: 'Form',
    width: 75,
    resizable: true,
    serverResponseKey: 'form'
  },
  {
    key: 'potency',
    name: 'Potency',
    width: 75,
    resizable: true,
    serverResponseKey: 'potency'
  },
  {
    key: 'price',
    name: 'Price',
    width: 100,
    formatters: [text => text.replace(/(\$?)([\d,.]+)/g, '$$$2')],
    resizable: true,
    serverResponseKey: 'price'
  },
  {
    key: 'prsStatus',
    name: 'PRS Status',
    width: 100,
    resizable: true,
    serverResponseKey: 'prsStatus'
  },
  {
    key: editBtnKey,
    name: '',
    width: 100,
    formatter: CustomSimpleCellFormatter,
    serverResponseKey: ''
  }
]
export const LocationItemList = props => {
  const history = useHistory()
  const initialParams = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    zoneId: ENTITY_PREFIX_LOCATION + props.match.params.locationId,
    from: props.location.state ? props.location.state.from : undefined
  }
  const [isLoading, setIsLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [qsParams, setQsParams] = useState(initialParams)
  const page = qsParams.pageNumber
  const term = qsParams.searchTerm
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])

  /**
   * Change the page number in state
   * @param {Number} pageNum Pagination number to request
   */
  const onPageChange = async pageNum => {
    setIsLoading(true)
    setQsParams({
      ...qsParams,
      pageNumber: pageNum
    })
  }

  /**
   * Updates the state and that causes a re-render which reloads data
   * @param {string} field query parameter to filter i.e ?searchTerm=<text_to_search>
   * @param {string} term term to search
   */
  const onSearch = (field, term) => {
    const searchBy = initialParams
    if (field && term) {
      searchBy[field] = term
    } else {
      delete searchBy.searchTerm
    }
    setIsLoading(true)
    setQsParams(searchBy)
  }

  if (props.location.state) {
    const baseParams = initialParams
    baseParams.from = props.location.state.from
    props.location.state = null
    setQsParams(baseParams)
  }

  const memoizedFetchData = useCallback(async params => {
    try {
      const { items, count, totalPages } = await ProductsRepository.filterBy(
        params
      )
      setIsLoading(false)
      setTotalCount(count)
      setData(items)
      setTotalPages(totalPages)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }, [])

  useEffect(() => {
    memoizedFetchData(qsParams)
  }, [memoizedFetchData, qsParams])

  /**
   * Adds the button to Edit the Item
   * @param {Array<LocationItemListRow>} rows
   * @return {Array<LocationItemListRow>}
   */
  const appendEditButtons = rows => {
    return rows.map(row => ({
      ...row,
      [editBtnKey]: (
        <Button
          primary
          as={Link}
          to={IN_LOCATION_PRODUCT_EDIT_LINK.replace(
            ':locationId',
            props.match.params.locationId
          ).replace(':productId', row.productNumber)}
          size='mini'
          content='Edit'
        />
      )
    }))
  }

  /**
   * @type {Array<LocationItemListRow>}
   */
  const rows = appendEditButtons(useMarshallResponse(gridModel, data))

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={gridModel}
      showEdit
      isLoading={isLoading}
      rows={rows}
      rowCount={rows.length}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      searchTerm={term}
      totalPages={totalPages}
      onSortChanged={(sortColum, sortDirection) => {
        return rows
      }}
      onRowDoubleClick={index => {
        history.push(IN_LOCATION_PRODUCT_EDIT_LINK.replace(':locationId', props.match.params.locationId).replace(':productId', rows[index].productNumber))
      }}
      onSearch={onSearch}
    />
  )
}

export default withRouter(LocationItemList)
