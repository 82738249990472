import React, { useContext, useEffect, useState } from 'react'
import ReactDataGrid from 'react-data-grid'
import { Button, Grid } from 'semantic-ui-react'
import PurchaseOrderContext from '../../../../context/PurchaseOrderContext'
import EmptyRowsView from '../../../common/emptyRowsView/EmptyRowsView'
import './ShipmentsGrid.css'

const ShipmentsGrid = ({ shipment }) => {
  const [shipmentStatus, setShipmentStatus] = useState(shipment.status)
  const [rows, setRows] = useState([])
  const { purchaseOrder, setStorablePurchaseOrder } = useContext(
    PurchaseOrderContext
  )

  const gridModel = [
    {
      key: 'productNumber',
      name: 'SKU',
      resizable: true,
      width: 70,
      editable: false
    },
    {
      key: 'name',
      name: 'Name',
      resizable: true,
      editable: false
    },
    {
      key: 'reqQty',
      name: 'Req. Qty',
      editable: false,
      width: 70,
      resizable: true
    },
    {
      key: 'shippedQty',
      name: 'Shipped',
      editable: false,
      width: 70,
      resizable: true
    },
    {
      key: 'qtyReceived',
      name: 'Received',
      editable: shipmentStatus !== 'closed',
      width: 70,
      resizable: true
    }
  ]

  const reopenShipment = () => {
    setShipmentStatus('open')
  }

  const onRowsUpdated = ({ fromRow, toRow, updated }) => {
    const editedProductIdx = shipment.products.findIndex(
      product => product.sku === rows[fromRow].productNumber
    )

    const { sku } = shipment.products[editedProductIdx]

    shipment.products[editedProductIdx] = {
      sku,
      qtyReceived: updated.qtyReceived
    }

    purchaseOrder.shipments[shipment.id] = shipment
    setStorablePurchaseOrder(purchaseOrder)

    const _rows = rows.slice()
    for (let i = fromRow; i <= toRow; i++) {
      _rows[i] = { ...rows[i], ...updated }
    }
    setRows(_rows)
  }

  useEffect(() => {
    if (!shipment.products) {
      return []
    }
    const rows = shipment.products.map(product => {
      const {
        sku: productNumber,
        quantity: reqQty,
        quantityShipped: shippedQty,
        qtyReceived,
        name
      } = product
      return { productNumber, reqQty, shippedQty, qtyReceived, name }
    })
    setRows(rows)
  }, [shipment.products])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  })

  return (
    <>
      <ReactDataGrid
        columns={gridModel}
        rowGetter={i => rows[i]}
        rowsCount={rows.length}
        minHeight={(rows.length + 1) * 35 + 20}
        emptyRowsView={EmptyRowsView}
        onGridRowsUpdated={onRowsUpdated}
        enableCellSelect
      />
      {shipmentStatus === 'closed' && (
        <Grid>
          <Grid.Row>
            <Grid.Column align='center'>
              <Button content='Re-Open' onClick={e => reopenShipment()} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  )
}

export default ShipmentsGrid
