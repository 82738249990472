import React from 'react'
import { MasterLayout } from '../../containers/masterLayout'
import LocationLayout from '../../containers/locationLayout/LocationLayout'

export default [
  {
    path: '/in-location/:locationId/*',
    exact: false,
    component: props => {
      return <LocationLayout {...props} />
    }
  },
  {
    path: '/*',
    exact: true,
    component: props => {
      return <MasterLayout {...props} />
    }
  }
]
