import React, { useEffect, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { Dropdown, Header, Icon, Image, Menu, Segment } from 'semantic-ui-react'
import LogoStores from '../../../../media/store_icon.png'
import LocationsRepository from '../../../../repositories/LocationsRepository'
import LocationTypesRepository from '../../../../repositories/LocationTypesRepository'
import {
  IN_LOCATION_ITEM_LIST_LINK,
  LocationLayoutRoutes
} from '../../../../routes/locationLayoutRoutes/LocationLayoutRoutes'

const LocationLayoutSidebar = props => {
  const [locationData, setLocationData] = useState([])
  const [locationTypes, setLocationTypes] = useState({})

  const history = useHistory()

  useEffect(() => {
    const getLocations = async () => {
      const {
        items: responseLocationTypes
      } = await LocationTypesRepository.getAll()
      setLocationTypes(responseLocationTypes)
    }
    getLocations()

    const fetchLocationData = async () => {
      const location = await LocationsRepository.get(
        props.match.params.locationId
      )
      setLocationData(location)
    }
    fetchLocationData()
  }, [props.match.params])

  const generateOptionsList = () => {
    const options = []
    if (locationTypes && Object.keys(locationTypes).length > 0) {
      for (const [, locationType] of Object.entries(locationTypes)) {
        options.push({
          key: locationType.PK,
          text: locationType.locationZoneName,
          value: locationType.locationZoneName,
          className: 'no-hover',
          content: (
            <Header
              content={locationType.locationZoneName}
              dividing
              size='tiny'
            />
          ),
          disabled: true
        })
        for (const { locationName, PK } of locationType.locations) {
          options.push({
            key: PK,
            text: locationName,
            value: PK
          })
        }
      }
    }

    return options
  }

  const handleChange = (e, { name, value }) => {
    history.push(IN_LOCATION_ITEM_LIST_LINK.replace(':locationId', value), {
      from: 'sideBar'
    })
  }

  const paintMenuItemsFromRoutes = () => {
    return LocationLayoutRoutes.map((route, key) => {
      return route.isLocationMenu ? (
        <Menu.Item
          key={key}
          as={Link}
          active={props.location.pathname.startsWith(
            route.path
              .replace(':locationId', props.match.params.locationId)
              .replace(':productId', props.match.params.productId)
          )}
          to={route.path
            .replace(':locationId', props.match.params.locationId)
            .replace(':productId', props.match.params.productId)}
        >
          {route.pageTitle}
        </Menu.Item>
      ) : null
    })
  }
  return (
    <>
      <Menu vertical inverted fluid attached>
        <Menu.Item as={Link} to='/'>
          <Icon name='reply' />
          Return to Master
        </Menu.Item>
      </Menu>
      <Segment attached>
        <Header as='h2'>
          <Image circular src={LogoStores} /> {locationData.locationName}
        </Header>
        <Dropdown
          placeholder='Select'
          fluid
          button
          selection
          options={generateOptionsList()}
          defaultValue={props.match.params.locationId}
          onChange={handleChange}
        />
      </Segment>
      <Menu vertical inverted fluid attached>
        {paintMenuItemsFromRoutes()}
      </Menu>
    </>
  )
}

export default withRouter(LocationLayoutSidebar)
