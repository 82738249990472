import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Grid, Header } from 'semantic-ui-react'
import { ItemList } from '../../../../common/list'
import PropTypes from 'prop-types'
import { PRODUCTS_ADD_LINK } from '../../../../../routes/masterLayoutRoutes/ProductsRoutes'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  PAGE_SIZE
} from '../../../../../repositories/variablesRepository'

const options = [{ key: '1', value: 'all', text: 'All' }, { key: '2', value: 'user', text: 'Manual Changes (Users)' }, { key: '3', value: 'other', text: 'Other' }]

const LogDescription = ({ setShowLog, showLog, openLog, setHistoryDetail, setShowLogDetail, showLogDetail, match, setShowLogParent }) => {
  const [history, setHistory] = useState([])
  const [historyCache, setHistoryCache] = useState({})
  const [totalPages, setTotalPages] = useState(1)
  const [pageLog, setPageLog] = useState(1)
  const initParams = {
    pageNumber: 1,
    pageSize: PAGE_SIZE
  }

  const onSearchLog = (term) => {
    getHistory({
      searchTerm: term,
      id: match.params.locationId,
      pageNumber: 1,
      pageSize: PAGE_SIZE
    })
  }

  const getHistory = async (params) => {
    try {
      const data = await ProductsRepository.getHistory(match.params.locationId, params)
      setPageLog(parseInt(data.page))
      setHistory(data.items)
      setTotalPages(data.totalPages)
      setHistoryCache(data.items)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setShowLog(openLog)
  }, [openLog, setShowLog])

  return (
    <>
      <Header as='h2' textAlign='center'>Change Log</Header>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column style={{ width: '15%', paddingTop: '10px' }}>
            <Header as='h5'>Show Changes</Header>
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              search
              selection
              options={options}
              defaultValue='all'
              onChange={(event, data) => {
                if (data.value === 'all') {
                  setHistory(historyCache)
                } else {
                  const result = historyCache.filter(item => item.type === data.value)
                  setHistory(result)
                }
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ItemList
        showCheckbox={false}
        columns={[
          { key: 'username', name: 'Username', width: 200 },
          { key: 'changedOn', name: 'Changed On', width: 200 },
          { key: 'detailed', name: 'Detailed' }
        ]}
        rows={history}
        rowCount={history.length}
        totalRows={history.length}
        onSortChanged={(sortColum, sortDirection) => history}
        activePage={pageLog}
        showEdit
        totalPages={totalPages}
        addLink={PRODUCTS_ADD_LINK}
        onPageChanged={(items) => {
          initParams.pageNumber = items
          getHistory(initParams)
        }}
        getProducts={(items) => {

        }}
        onSearch={onSearchLog}
        onRowDoubleClick={index => {
          setHistoryDetail(history[index].description)
          setShowLogDetail(!showLogDetail)
        }}
        topSegment={
          <Button
            style={{ float: 'left' }} type='button' content='Close' onClick={() => {
              setShowLogParent(!showLog)
            }}
          />
        }
      />
    </>
  )
}

LogDescription.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired
    })
  }),
  setLoading: PropTypes.func,
  openLog: PropTypes.bool
}

export default LogDescription
