import React from 'react'
import { Segment, Header } from 'semantic-ui-react'

const HeaderFormGroup = ({ title, children }) => {
  return (
    <Segment.Group>
      <Header as='h4' attached='top' style={{ color: '#092D74' }}>
        {title}
      </Header>
      <Segment attached>{children}</Segment>
    </Segment.Group>
  )
}

export default HeaderFormGroup
