import React from 'react'
import WarehousePOAddEdit from '../../components/modules/location/replenishment/WarehousePOAddEdit'
import WarehousePOList from '../../components/modules/location/replenishment/WarehousePOList'

export const IN_LOCATION_WAREHOUSE_PO_ROOT =
  '/in-location/:locationId/warehouse-po'
export const IN_LOCATION_WAREHOUSE_PO_LIST_LINK =
  IN_LOCATION_WAREHOUSE_PO_ROOT + '/list'
export const IN_LOCATION_WAREHOUSE_PO_ADD =
  IN_LOCATION_WAREHOUSE_PO_ROOT + '/add'
export const IN_LOCATION_WAREHOUSE_PO_VIEW =
  IN_LOCATION_WAREHOUSE_PO_ROOT + '/view/:poId'
export const IN_LOCATION_WAREHOUSE_PO =
  IN_LOCATION_WAREHOUSE_PO_ROOT + '/:action(add|edit|draft|view)/:poId?'

const WarehousePORoutes = [
  {
    path: IN_LOCATION_WAREHOUSE_PO_LIST_LINK,
    pageTitle: "Warehouse PO's",
    icon: 'align center',
    isLocationMenu: true,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: "Warehouse PO's List"
      }
    ],
    exact: true,
    component: props => <WarehousePOList {...props} />
  },
  {
    path: IN_LOCATION_WAREHOUSE_PO,
    pageTitle: 'Purchase Order',
    icon: 'align center',
    isLocationMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Purchase Order'
      }
    ],
    exact: true,
    component: props => <WarehousePOAddEdit {...props} />
  }
]

export default WarehousePORoutes
