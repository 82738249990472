import React, { useEffect, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { Button, Grid, Message, Segment, Tab } from 'semantic-ui-react'
import LocationProductContext from '../../../../../context/LocationProductContext'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  ENTITY_PREFIX_LOCATION,
  ENTITY_PREFIX_PRODUCT
} from '../../../../../repositories/variablesRepository'
import {
  IN_LOCATION_ITEM_LIST_LINK,
  IN_LOCATION_PRODUCT_EDIT_LINK
} from '../../../../../routes/locationLayoutRoutes/LocationLayoutRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb/BreadCrumb'
import GeneralView from './GeneralView'
import InventoryView from './InventoryView'
import ProductAutocomplete from './ProductAutocomplete'

const ProductConfig = ({ route, match, ...props }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [showLog, setShowLog] = useState(false)
  const [topMessage, setTopMessage] = useState()
  const [topErrors, setTopErrors] = useState([])
  const [product, setProduct] = useState({
    sku: '',
    productName: '',
    prsStatus: '',
    cost: '0',
    msrp: '0',
    price: '',
    productTaxCode: '0',
    brand: '',
    upcCode: '0',
    category: '',
    form: '-',
    size: '-',
    potency: '-',
    replenishmentType: '-',
    attributes: '',
    minimumQuantity: '',
    reorderDays: '',
    targetDays: '',
    locationAvailQty: '0',
    locationDaysAvail: '0',
    monthlyUsage: '0'
  })

  route.breadCrumbs = route.breadCrumbs.map(bc => {
    if (!bc.path) {
      return bc
    }
    return {
      ...bc,
      path: bc.path.replace(':locationId', match.params.locationId)
    }
  })

  useEffect(() => {
    const getProduct = async () => {
      const {
        sku,
        productName,
        category,
        brand,
        price,
        attributes,
        minimumQuantity,
        reorderDays,
        targetDays,
        locationAvailQty,
        locationDaysAvail,
        monthlyUsage,
        pricingZone,
        priceOverwritten,
        originalProduct
      } = await ProductsRepository.getByZoneId(
        ENTITY_PREFIX_PRODUCT + match.params.productId,
        ENTITY_PREFIX_LOCATION + match.params.locationId
      )
      setProduct(prevInitialValues => ({
        ...prevInitialValues,
        sku,
        productNumber: sku,
        productName,
        category,
        brand,
        price,
        attributes,
        minimumQuantity: minimumQuantity || '',
        reorderDays: reorderDays || '',
        targetDays: targetDays || '',
        locationAvailQty: locationAvailQty || '',
        locationDaysAvail: locationDaysAvail || '',
        monthlyUsage: monthlyUsage || '',
        pricingZone,
        priceOverwritten,
        originalProduct
      }))

      setLoading(false)
    }

    getProduct()
  }, [match.params.productId, match.params.locationId, setLoading])

  const renderErrors = () => {
    if (!topErrors || topErrors.length === 0) {
      return <></>
    }

    const errors = topErrors.map((error, index) => {
      let message = error
      return <Message.Item key={index}>{message}</Message.Item>
    })
    return (
      <Message negative>
        <Message.Header>Errors:</Message.Header>
        <Message.List>{errors}</Message.List>
      </Message>
    )
  }

  const resetTopMessages = () => {
    setTopMessage(undefined)
    setTopErrors([])
  }

  const onSelectProduct = productId => {
    setLoading(true)
    history.push(
      IN_LOCATION_PRODUCT_EDIT_LINK.replace(
        ':locationId',
        match.params.locationId
      ).replace(':productId', productId)
    )
  }

  return (
    <LocationProductContext.Provider
      value={{
        loading,
        setLoading,
        product,
        setTopMessage,
        setTopErrors,
        resetTopMessages
      }}
    >
      <BreadCrumb route={route} />
      <Segment.Group>
        <Segment secondary>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column>&nbsp;</Grid.Column>
              <Grid.Column
                width={9}
                style={{
                  maxWidth: '100%',
                  padding: 0
                }}
              >
                <ProductAutocomplete
                  defaultSelected={match.params.productId}
                  onSelect={onSelectProduct}
                  filterProductsBy={{
                    zoneId: `${ENTITY_PREFIX_LOCATION}${match.params.locationId}`,
                    pageSize: 9999
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign='right' style={{ maxWidth: '12rem' }}>
                <Button.Group>
                  <Button
                    primary
                    to='#'
                    icon='file alternate outline'
                    labelPosition='left'
                    content='View Log'
                    onClick={() => {
                      setShowLog(!showLog)
                    }}
                  />
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment loading={loading}>
          {topMessage && <Message positive content={topMessage} />}

          {renderErrors()}
          <Tab
            panes={[
              {
                menuItem: 'General',
                pane: (
                  <Tab.Pane key='tab4'>
                    <GeneralView
                      openLog={showLog}
                      setShowLogParent={setShowLog}
                    />
                  </Tab.Pane>
                )
              },
              {
                menuItem: 'Inventory',
                pane: (
                  <Tab.Pane key='tab5'>
                    <InventoryView />
                  </Tab.Pane>
                )
              },
              {
                menuItem: 'Images',
                pane: (
                  <Tab.Pane key='tab6' style={{ textAlign: 'center' }}>
                    <img
                      src='https://cdn.pipingrock.com/favicon.ico?v=2020120301'
                      alt=''
                    />
                  </Tab.Pane>
                )
              }
            ]}
            renderActiveOnly={false}
          />
        </Segment>
        <Segment secondary>
          <Button.Group>
            <Button
              as={Link}
              to={IN_LOCATION_ITEM_LIST_LINK.replace(
                ':locationId',
                match.params.locationId
              )}
              icon='arrow left'
              labelPosition='left'
              content='Back'
            />
          </Button.Group>
        </Segment>
      </Segment.Group>
    </LocationProductContext.Provider>
  )
}

export default withRouter(ProductConfig)
