import _ from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import { withRouter } from 'react-router'
import { Form, Input, Message, TextArea } from 'semantic-ui-react'
import validator from 'validator'
import {
  NotFoundException,
  withErrorHandler
} from '../../../../../hoc/withErrorHandler'
import { withModal } from '../../../../../hoc/withModal'
import { withRequest } from '../../../../../hoc/withRequest'
import LocationTypesRepository from '../../../../../repositories/LocationTypesRepository'
import {
  LOCATION_TYPES_EDIT_LINK,
  LOCATION_TYPES_LISTING_LINK
} from '../../../../../routes/masterLayoutRoutes/LocationTypesRoutes'
import { sleep } from '../../../../../utils'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ERROR_BLANK } from '../../../../common/errors'
import { EditForm } from '../../../../common/form/edit-form'

/**
 * @typedef {import('./location-type').LocationType} LocationType
 */

class LocationTypeAddEdit extends React.Component {
  /** @type {LocationType} */
  state = {
    id: undefined,
    zoneName: '',
    description: ''
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      _.isEqual(this.state, nextState) &&
      _.isEqual(this.props.requestState, nextProps.requestState)
    ) {
      const { id } = this.props.match.params
      if (id) {
        return true
      }
      return false
    }
    return true
  }

  async componentDidMount () {
    const { id } = this.props.match.params
    if (id) {
      try {
        this.props.setLoading(true)
        const response = await LocationTypesRepository.get(id)

        if (_.isEmpty(response)) {
          throw new Error(NotFoundException)
        }

        const { description, locationZoneName: zoneName } = response.Item

        this.setState({
          id,
          zoneName,
          description
        })
        this.props.setSuccess({
          message: `"${zoneName}" was saved successfully`
        })
      } catch (err) {
        console.log(err)
        this.props.handleError(err)
      }
      this.props.setLoading(false)
    }
  }

  onSuccess = (uniqueId, formValues) => {
    this.props.setSuccess({
      message: `"${formValues.zoneName}" was saved successfully`
    })
    this.props.history.push(`${LOCATION_TYPES_EDIT_LINK}/${uniqueId}?add=true`)
  }

  onFailure = err => {
    this.props.setFailure(err)
  }

  validateValues = values => {
    const errors = {}
    if (validator.isEmpty(values.zoneName)) {
      errors.zoneName = ERROR_BLANK
    }
    if (validator.isEmpty(values.description)) {
      errors.description = ERROR_BLANK
    }
    return errors
  }

  onSubmit = async (values, setSubmitting, setErrors) => {
    const { zoneName, description } = values
    setSubmitting(true)
    let response
    try {
      response = await LocationTypesRepository.save({
        zoneName,
        description,
        id: this.state.id
      })
      await sleep()
    } catch (err) {
      this.onFailure(err.response.data)
      setSubmitting(false)
      return
    }

    setSubmitting(false)
    this.onSuccess(response.uniqueId, values)
  }

  form = (
    { zoneName, description },
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    touched
  ) => {
    const { add } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    })
    const { error, message } = this.props.requestState
    return (
      <>
        {add && message ? (
          <Message positive header='Success' content={message} />
        ) : (
          ''
        )}
        {error ? <Message negative header='Error' content={message} /> : ''}
        <Form.Field
          id='zoneName'
          name='zoneName'
          onChange={handleChange}
          onBlur={handleBlur}
          control={Input}
          label='Name'
          error={touched.zoneName && errors.zoneName}
          placeholder='Name'
          value={zoneName}
          readOnly={!!this.props.match.params.id}
        />
        <Form.Field
          id='description'
          name='description'
          onChange={handleChange}
          onBlur={handleBlur}
          control={TextArea}
          label='Description'
          error={touched.description && errors.description}
          placeholder='Description'
          value={description}
        />
      </>
    )
  }

  render () {
    const { zoneName, description } = this.state
    return (
      <>
        {this.props.renderModal()}
        <EditForm
          loading={this.props.requestState.loading}
          initialValues={{ zoneName, description }}
          validate={this.validateValues}
          onSubmit={this.onSubmit}
          header={<h3>{this.props.route.pageTitle}</h3>}
          breadCrumb={<BreadCrumb route={this.props.route} />}
          form={this.form}
          cancel={LOCATION_TYPES_LISTING_LINK}
        />
      </>
    )
  }
}

LocationTypeAddEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  route: PropTypes.shape({
    pageTitle: PropTypes.string
  }),
  requestState: PropTypes.shape({
    error: PropTypes.any,
    message: PropTypes.string,
    loading: PropTypes.bool
  }),
  setLoading: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setFailure: PropTypes.func.isRequired,
  renderModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired
}

export default withRouter(
  withRequest(withModal(withErrorHandler(LocationTypeAddEdit)))
)
