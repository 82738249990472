import PropTypes from 'prop-types'
import React from 'react'
import { Modal, Segment } from 'semantic-ui-react'

const Popup = props => {
  return (
    <Modal
      closeIcon
      size={props.size ? props.size : 'tiny'}
      open={props.open}
      dimmer='blurring'
      onClose={props.onClose}
    >
      <Segment
        style={{
          zIndex: 1000
        }}
      >
        {props.body}
      </Segment>
    </Modal>
  )
}

Popup.propTypes = {
  body: PropTypes.element,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string
}
export default Popup
