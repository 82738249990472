import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Form, Grid, Input, Select, TextArea } from 'semantic-ui-react'
import LocationProductContext from '../../../../../context/LocationProductContext'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  ENTITY_PREFIX_LOCATION,
  ENTITY_PREFIX_PRODUCT
} from '../../../../../repositories/variablesRepository'
import '../../../../../styles/FormStyle.css'
import Popup from '../../../../common/popup/Popup'
import LogDescription from './LogDescription'
import LogDetail from './LogDetail'
import validate from './validators/GeneralViewValidator'
import '../../../../../styles/ProductViewStyle.css'

/**
 *
 * @param {{openLog: boolean, match: Object, setShowLogParent: Function}} param0
 */
const GeneralView = ({ openLog, match, setShowLogParent }) => {
  const context = useContext(LocationProductContext)
  const {
    product,
    setLoading,
    setTopMessage,
    setTopErrors,
    resetTopMessages
  } = context
  const [showLog, setShowLog] = useState(false)
  const [showLogDetail, setShowLogDetail] = useState(false)
  const [historyDetail, setHistoryDetail] = useState({})

  const getLog = () => {
    return (
      <LogDescription
        setShowLog={setShowLog}
        showLog={showLog}
        setShowLogParent={setShowLogParent}
        setLoading={setLoading}
        match={match}
        setHistoryDetail={setHistoryDetail}
        setShowLogDetail={setShowLogDetail}
        showLogDetail={showLogDetail}
      />
    )
  }

  useEffect(() => {
    setShowLog(openLog)
  }, [openLog])

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true)
      resetTopMessages([])
      await ProductsRepository.saveByZone(
        ENTITY_PREFIX_PRODUCT + match.params.productId,
        ENTITY_PREFIX_LOCATION + match.params.locationId,
        values
      )
      setTopMessage('General product data updated successfully')
    } catch (err) {
      setTopErrors([err.message])
    }
    setLoading(false)
  }

  const validateForm = values => {
    return validate(values, {})
  }

  /**
   *
   * @param {string} fieldName
   * @param {object} values - Formik product value
   * @param {function} setFieldValue - Formik method
   * @returns {null|*}
   */
  const displayUndo = (fieldName, values, setFieldValue) => {
    if (fieldName === 'price') {
      if (values.priceOverwritten || (values.originalProduct && values.originalProduct.price !== values.price)) {
        values.priceOverwritten = true
        const fallbackPrice = ('price' in values.pricingZone) ? values.pricingZone.price : values.originalProduct.price
        if (values.price !== fallbackPrice) {
          return undoLink(fieldName, fallbackPrice)
        }
      }
    } else if(fieldName === 'attributes') {
      if (values.originalProduct && values.originalProduct.attributes !== values.attributes) {
        return undoLink(fieldName, values.originalProduct.attributes)
      }
    }
    function undoLink(fieldName, value) {
      return (
          <i aria-hidden="true" className="undo big icon float-right" onClick={() => { setFieldValue(fieldName, value) }}></i>
      )
    }
    return null
  }

  return (
    <>
      <Formik
        initialValues={product}
        enableReinitialize
        validate={validateForm}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid columns='equal'>
                <Grid.Row>
                  <Grid.Column>
                    <Grid.Column width={4}>
                      <Form.Field
                        id='productName'
                        name='productName'
                        control={Input}
                        label='Product Name'
                        placeholder='Product Name'
                        readOnly
                        value={values.productName}
                        error={touched.productName && errors.productName}
                        onChange={handleChange}
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='sku'
                      name='sku'
                      control={Input}
                      label='Product Number'
                      placeholder='productNumber'
                      readOnly
                      value={values.sku}
                      error={touched.sku && errors.sku}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='prsStatus'
                      name='prsStatus'
                      control={Input}
                      label='PRS Status'
                      placeholder='PRS Status'
                      readOnly
                      value={values.prsStatus}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='brand'
                      name='brand'
                      control={Input}
                      label='Brand'
                      placeholder='Brand'
                      readOnly
                      value={values.brand}
                      error={touched.brand && errors.brand}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='upcCode'
                      name='upcCode'
                      control={Input}
                      label='UPC Code'
                      placeholder='UPC Code'
                      readOnly
                      value={values.upcCode}
                      error={touched.upcCode && errors.upcCode}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='category'
                      name='category'
                      control={Input}
                      label='Category'
                      placeholder='Category'
                      readOnly
                      value={values.category}
                      error={touched.category && errors.category}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='cost'
                      name='cost'
                      icon='dollar'
                      iconPosition='left'
                      control={Input}
                      label='Cost'
                      placeholder='Cost'
                      readOnly
                      value={values.cost}
                      error={touched.cost && errors.cost}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='msrp'
                      name='msrp'
                      control={Input}
                      label='MSRP'
                      placeholder='MSRP'
                      readOnly
                      value={values.msrp}
                      error={touched.msrp && errors.msrp}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                      <label className='float-left'><b>Price</b></label>
                      { displayUndo('price', values, setFieldValue) }
                      <Form.Field id='price'
                             name='price'
                             icon='dollar'
                             iconPosition='left'
                             control={Input}
                             placeholder='Price'
                             value={values.price}
                             error={touched.price && errors.price}
                             onChange={handleChange} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='form'
                      name='form'
                      control={Input}
                      label='Form'
                      placeholder='Form'
                      readOnly
                      value={values.form}
                      error={touched.form && errors.form}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='size'
                      name='size'
                      control={Input}
                      label='Size'
                      placeholder='Size'
                      readOnly
                      value={values.size}
                      error={touched.size && errors.size}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='potency'
                      name='potency'
                      control={Input}
                      label='Potency'
                      placeholder='Potency'
                      readOnly
                      value={values.potency}
                      error={touched.potency && errors.potency}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='productTaxCode'
                      name='productTaxCode'
                      control={Input}
                      label='Product Tax Code'
                      placeholder='Product Tax Code'
                      readOnly
                      value={values.productTaxCode}
                      error={touched.productTaxCode && errors.productTaxCode}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form.Field
                      id='replenishmentType'
                      name='replenishmentType'
                      control={Select}
                      label='Replenishment Type'
                      placeholder='Replenishment Type'
                      readOnly
                      value={values.replenishmentType}
                      error={
                        touched.replenishmentType && errors.replenishmentType
                      }
                      options={[
                        {
                          key: 'VENDOR_DIRECT',
                          value: 'VENDOR_DIRECT',
                          text: 'Vendor Direct'
                        },
                        {
                          key: 'FIRST_PARTY',
                          value: 'FIRST_PARTY',
                          text: 'First Party'
                        }
                      ]}
                      selection
                      onChange={(opt, el) => {
                        setFieldValue('replenishmentType', el.value)
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <label className='float-left'><b>Attributes</b></label>
                    { displayUndo('attributes', values, setFieldValue) }
                    <Form.Field
                      id='attributes'
                      name='attributes'
                      control={TextArea}
                      placeholder='Attributes'
                      value={values.attributes}
                      error={touched.attributes && errors.attributes}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16} align='right'>
                    <Button
                      primary
                      type='submit'
                      labelPosition='right'
                      icon='save'
                      content='Save'
                      readOnly={isSubmitting}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )
        }}
      </Formik>

      <Popup
        open={showLog}
        body={getLog()}
        onClose={() => {
          setShowLogParent(!showLog)
        }}
        size='large'
      />
      <Popup
        open={showLogDetail}
        body={<LogDetail historyDetail={historyDetail} match={match} />}
        onClose={() => setShowLogDetail(!showLogDetail)}
        size='small'
      />
    </>
  )
}

GeneralView.propTypes = {
  openLog: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired
    })
  })
}

export default withRouter(GeneralView)
