import React, { useEffect, useState } from 'react'
import LocationTypeContainer from './LocationTypeContainer'
import LoadingScreen from '../../../common/loadingScreen'
import LocationTypesRepository from '../../../../repositories/LocationTypesRepository'

export const MasterDashboard = () => {
  const [locationTypes, setLocationTypes] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getLocations = async () => {
      const { items: responseLocationTypes } = await LocationTypesRepository.getAll({ pageSize: 9999 })
      setLocationTypes(responseLocationTypes)
      setLoading(false)
    }

    getLocations()
  }, [])

  const renderLocationTypes = () => {
    const innerLocationTypes = []
    if (locationTypes && Object.keys(locationTypes).length > 0) {
      for (const [, locationType] of Object.entries(locationTypes)) {
        innerLocationTypes.push(
          <LocationTypeContainer
            key={locationType.PK}
            locationTypeName={locationType.locationZoneName}
            locations={locationType.locations}
          />
        )
      }
    }
    return innerLocationTypes
  }

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && renderLocationTypes()}
    </>
  )
}
