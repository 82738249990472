import RequestHandler from './TransactionsApiRequestHandler'

const basePath = '/purchase-order'

const filterBy = params => {
  return RequestHandler.get(`${basePath}/list`, {
    queryStringParameters: params
  })
}

const getAll = () => RequestHandler.get(`${basePath}/list`)

const get = id => RequestHandler.get(`${basePath}/${id}`)

const save = po => RequestHandler.post(`${basePath}/checklist`, po)

export default {
  filterBy,
  get,
  getAll,
  save
}
