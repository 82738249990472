import React from 'react'
import { Redirect } from 'react-router-dom'

export const NotFoundRedirect = () => <Redirect to='/404' />

const NotFound = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        height: '10rem'
      }}
    >
      <div>
        <img
          style={{ width: '10rem', height: '10rem', margin: '0 auto' }}
          src={`${process.env.PUBLIC_URL}/images/disconnected-chains.svg`}
          alt='404-chains'
        />
      </div>
      <div>
        <h1 style={{ fontSize: '4rem' }}>404</h1>
        <h3 style={{ fontSize: '2rem' }}>Resource Not Found</h3>
      </div>
    </div>
  )
}

export default NotFound
