import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { toast } from 'react-toastify'
import ReactDataGrid from 'react-data-grid'
import { Link, withRouter } from 'react-router-dom'
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  Segment, Select,
  Tab,
  TextArea
} from 'semantic-ui-react'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import { ENTITY_PREFIX_PRODUCT } from '../../../../../repositories/variablesRepository'
import {
  PRODUCTS_LISTING_LINK,
  PRODUCTS_VIEW_LINK
} from '../../../../../routes/masterLayoutRoutes/ProductsRoutes'
import '../../../../../styles/FormStyle.css'
import { ItemList } from '../../../../common/assignItems/ItemList'
import { BreadCrumb } from '../../../../common/breadCrumb/BreadCrumb'
import Popup from '../../../../common/popup/Popup'
import ProductAutocomplete from '../../../location/items/productConfig/ProductAutocomplete'
import validate from '../../../location/items/productConfig/validators/GeneralViewValidator'

/**
 * @typedef {import('./product-inventory-row').ProductInventoryRow} ProductInventoryRow
 * @typedef {import('../../../../../types/product').Product} Product
 * @typedef {import('../../../../../repositories/fulfillmentApi/product-inventory-response').ProductInventory} ProductInventory
 * @typedef {import('semantic-ui-react').InputOnChangeData} InputOnChangeData
 * @typedef {import('react').ChangeEvent} ChangeEvent
 * @typedef {import('react').ChangeEventHandler} ChangeEventHandler
 */

const options = [
  { key: '1', value: 'all', text: 'All' },
  { key: '2', value: 'user', text: 'Manual Changes (Users)' },
  { key: '3', value: 'other', text: 'Other' }
]

const notify = message => toast(message)

class ProductView extends React.Component {

  state = {
    item: {
      PK: '',
      SK: '',
      brand: '',
      category: '',
      description: '',
      price: '',
      productName: '',
      sku: '',
      minimumQuantity: '',
      reorderDays: '',
      targetDays: '',
      inventory: []
    },
    selectedType: 'all',
    history: [],
    historyCache: [],
    historyDetail: [],
    showLog: false,
    showLogDetail: false,
    loading: true
  }

  async fetchData () {
    const { id } = this.props.match.params
    if (id) {
      try {
        const data = await ProductsRepository.getFullProduct(id)
        const {
          PK,
          SK,
          brand,
          category,
          description,
          price,
          productName,
          sku,
          minimumQuantity,
          reorderDays,
          targetDays,
          inventory
        } = data
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          item: {
            id,
            PK,
            SK,
            brand,
            category,
            description,
            price,
            productName,
            productNumber: sku,
            sku,
            minimumQuantity,
            reorderDays,
            targetDays,
            inventory
          }
        }))
      } catch (err) {
        console.log(err)
      }
      try {
        const data = await ProductsRepository.getHistory(id)
        this.setState({ history: data, historyCache: data })
      } catch (err) {
        console.log(err)
      }
    }
  }

  async componentDidUpdate (prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ loading: true })
      this.fetchData()
    }
  }

  async componentDidMount () {
    this.fetchData()
  }

  onSubmit = async (values, { setSubmitting }) => {
    this.setState({ loading: true })
    try {
      await ProductsRepository.saveByProduct(
        ENTITY_PREFIX_PRODUCT + this.props.match.params.id,
        values
      )
      this.setState({ loading: false })
      notify('Product Saved Successfully')
    } catch (err) {
      this.setState({ loading: false })
      console.error(err.message)
    }
  }

  formGeneral = () => {
    const item = this.state.item
    let rows = item.inventory.map(
      /** @type {(inventory: ProductInventory) => ProductInventoryRow} */
      inventory => ({
        location: inventory.warehouseCode,
        onHand: inventory.gLocalOnHand,
        onOrder: inventory.gLocalOrderedNotShipped,
        available: inventory.available
      })
    )

    return (
      <Tab.Pane key='tab4'>
        <Formik
          initialValues={item}
          enableReinitialize
          onSubmit={this.onSubmit}
        >
          {({ values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid columns='equal'>
                  <Grid.Row>
                    <Grid.Column>
                      <Grid.Column width={4}>
                        <Form.Field
                          id='productName'
                          name='item.productName'
                          control={Input}
                          label='Product Name'
                          value={item.productName}
                          placeholder='Product Name'
                          readOnly
                        />
                      </Grid.Column>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form.Field
                        id='sku'
                        name='item.sku'
                        control={Input}
                        label='Product Number'
                        placeholder='sku'
                        value={item.sku}
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='prsStatus'
                        name='item.prsStatus'
                        control={Input}
                        label='PRS Status'
                        value={item.prsStatus}
                        placeholder='PRS Status'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='brand'
                        name='item.brand'
                        control={Input}
                        label='Brand'
                        value={item.brand}
                        placeholder='Brand'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='upcCode'
                        name='item.upcCode'
                        control={Input}
                        label='UPC Code'
                        value={item.upcCode}
                        placeholder='UPC Code'
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form.Field
                        id='category'
                        name='item.category'
                        control={Input}
                        label='Category'
                        value={item.category}
                        placeholder='Category'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='cost'
                        name='item.cost'
                        control={Input}
                        label='Cost'
                        value={item.cost}
                        placeholder='Cost'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='msrp'
                        name='item.msrp'
                        control={Input}
                        label='MSRP'
                        value={item.msrp}
                        placeholder='MSRP'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='price'
                        name='item.price'
                        control={Input}
                        label='Price'
                        value={item.price}
                        placeholder='Price'
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form.Field
                        id='form'
                        name='item.form'
                        control={Input}
                        label='Form'
                        value={item.form}
                        placeholder='Form'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='size'
                        name='item.size'
                        control={Input}
                        label='Size'
                        value={item.size}
                        placeholder='Size'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='potency'
                        name='item.potency'
                        control={Input}
                        label='Potency'
                        value={item.potency}
                        placeholder='Potency'
                        readOnly
                      />
                    </Grid.Column>

                    <Grid.Column width={4}>
                      <Form.Field
                        id='productTaxCode'
                        name='item.productTaxCode'
                        control={Input}
                        label='Product Tax Code'
                        value={item.productTaxCode}
                        placeholder='Product Tax Code'
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Form.Field
                        id='replenishmentType'
                        name='replenishmentType'
                        control={Select}
                        label='Replenishment Type'
                        placeholder='Replenishment Type'
                        readOnly
                        value={item.replenishmentType}
                        error={
                          touched.replenishmentType && errors.replenishmentType
                        }
                        options={[
                          {
                            key: 'VENDOR_DIRECT',
                            value: 'VENDOR_DIRECT',
                            text: 'Vendor Direct'
                          },
                          {
                            key: 'FIRST_PARTY',
                            value: 'FIRST_PARTY',
                            text: 'First Party'
                          }
                        ]}
                        selection
                        onChange={(opt, el) => {
                          setFieldValue('replenishmentType', el.value)
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={15}>
                      <ReactDataGrid
                        minHeight={150}
                        columns={[
                          { key: 'location', name: 'Location', width: 100 },
                          { key: 'onHand', name: 'On Hand', width: 350 },
                          { key: 'onOrder', name: 'On Order' },
                          { key: 'available', name: 'Available' }
                        ]}
                        rowGetter={i => rows[i]}
                        rowsCount={10}
                        emptyRowsView={() => (
                          <div
                            style={{
                              textAlign: 'center',
                              padding: '100px',
                              fontSize: '20px'
                            }}
                          >
                            No results found
                          </div>
                        )}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Form.Field
                        control={TextArea}
                        label='Attributes'
                        placeholder=''
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Tab.Pane>
    )
  }

  /**
   * Function used to validate the input to not be less than zero
   * @param {InputOnChangeData} txt
   * @param {ChangeEvent} ev
   * @param {ChangeEventHandler} handleChange
   */
  validateOnchange = (txt, ev, handleChange) => {
    const numbers = /^[0-9]+$/
    if (txt.value.match(numbers) || txt.value === '') {
      handleChange(ev)
    }
  }

  formInventory = () => {
    const validateForm = values => {
      return validate(values, {})
    }

    return (
      <Tab.Pane key='tab5'>
        <Formik
          initialValues={this.state.item}
          enableReinitialize
          validate={validateForm}
          onSubmit={this.onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid columns='equal'>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Form.Field
                        type='input'
                        step='1'
                        id='minimumQuantity'
                        name='minimumQuantity'
                        control={Input}
                        label='Minimum Qty'
                        placeholder='Minimum Qty'
                        value={values.minimumQuantity || ''}
                        onChange={(ev, txt) => {
                          this.validateOnchange(txt, ev, handleChange)
                        }}
                        error={
                          touched.minimumQuantity && errors.minimumQuantity
                        }
                      />
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <Form.Field
                        type='input'
                        id='reorderDays'
                        name='reorderDays'
                        control={Input}
                        label='Reorder Days'
                        placeholder='Reorder Days'
                        value={values.reorderDays || ''}
                        onChange={(ev, txt) => {
                          this.validateOnchange(txt, ev, handleChange)
                        }}
                        error={touched.reorderDays && errors.reorderDays}
                      />
                    </Grid.Column>

                    <Grid.Column width={5}>
                      <Form.Field
                        type='input'
                        id='targetDays'
                        name='targetDays'
                        control={Input}
                        label='Target Days'
                        placeholder='Target Days'
                        value={values.targetDays || ''}
                        onChange={(ev, txt) => {
                          this.validateOnchange(txt, ev, handleChange)
                        }}
                        error={touched.targetDays && errors.targetDays}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={16} align='right'>
                      <Button
                        primary
                        type='submit'
                        labelPosition='right'
                        icon='save'
                        content='Save'
                        readOnly={isSubmitting}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Tab.Pane>
    )
  }

  getLog = () => {
    return (
      <>
        <Header as='h2' textAlign={'center'}>
          Change Log
        </Header>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column style={{ width: '15%', paddingTop: '10px' }}>
              <Header as='h5'>Show Changes</Header>
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                search
                selection
                options={options}
                onChange={(event, data) => {
                  if (data.value === 'all') {
                    this.setState({ history: this.state.historyCache })
                  } else {
                    const result = this.state.historyCache.filter(
                      item => item.type === data.value
                    )
                    this.setState({ history: result })
                  }
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ItemList
          breadCrumb={<></>}
          showCheckbox={false}
          columns={[
            { key: 'username', name: 'Username', width: 200 },
            { key: 'changedOn', name: 'Changed On', width: 200 },
            { key: 'modifiedIn', name: 'Detailed' }
          ]}
          rows={this.state.history.items}
          rowCount={this.state.history.count}
          totalRows={this.state.history.count}
          onSortChanged={(sortColum, sortDirection) => {
            return this.state.history
          }}
          totalPages={1}
          onPageChanged={items => {
            console.log(items)
          }}
          getProducts={items => {
            console.log(items)
          }}
          onRowDoubleClick={index => {
            this.setState({
              historyDetail: this.state.history.items[index].description
            })
            this.toggleLogDetails()
          }}
          secondary={
            <Button
              type='button'
              content='Close'
              onClick={() => {
                this.setState({ showLog: !this.state.showLog })
              }}
            />
          }
        />
      </>
    )
  }

  toggleLogDetails = () => {
    this.setState({ showLogDetail: !this.state.showLogDetail })
  }

  getLogDetails = () => {
    return (
      <>
        <Header as='h2' textAlign={'center'}>
          Change Log
        </Header>
        <ItemList
          breadCrumb={<></>}
          columns={[
            { key: 'field', name: 'Field', width: 200 },
            { key: 'oldValue', name: 'Old Value', width: 200 },
            { key: 'newValue', name: 'New Value' }
          ]}
          rows={this.state.historyDetail}
          rowCount={this.state.historyDetail.length}
          totalRows={this.state.historyDetail.length}
          totalPages={1}
          onPageChanged={items => {
            console.log(items)
          }}
          getProducts={items => {
            console.log(items)
          }}
          showCheckbox={false}
          secondary={<></>}
          minHeight={1}
        />
      </>
    )
  }

  /**
   *
   * @param {string} selectedProductId
   */
  onSelectProduct = selectedProductId => {
    this.props.history.push(`${PRODUCTS_VIEW_LINK}/${selectedProductId}`)
    this.forceUpdate()
  }

  render () {
    return (
      <>
        <BreadCrumb route={this.props.route} />
        <Segment.Group>
          <Segment secondary>
            <Grid columns='equal'>
              <Grid.Row>
                <Grid.Column>&nbsp;</Grid.Column>
                <Grid.Column
                  width={9}
                  style={{
                    maxWidth: '100%',
                    padding: 0
                  }}
                >
                  <ProductAutocomplete
                    defaultSelected={this.props.match.params.id}
                    onSelect={this.onSelectProduct}
                  />
                </Grid.Column>
                <Grid.Column textAlign='right' style={{ maxWidth: '12rem' }}>
                  <Button.Group>
                    <Button
                      primary
                      icon='file alternate outline'
                      labelPosition='left'
                      content='View Log'
                      onClick={() => {
                        this.setState({ showLog: !this.state.showLog })
                      }}
                    />
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment loading={this.state.loading}>
            <Tab
              panes={[
                {
                  menuItem: 'General',
                  pane: this.formGeneral()
                },
                {
                  menuItem: 'Inventory',
                  pane: this.formInventory()
                }
              ]}
              renderActiveOnly={false}
            />
          </Segment>
          <Segment secondary>
            <Button.Group>
              <Button
                as={Link}
                to={PRODUCTS_LISTING_LINK}
                icon='arrow left'
                labelPosition='left'
                content='Back'
              />
            </Button.Group>
          </Segment>
        </Segment.Group>

        <Popup
          open={this.state.showLog}
          body={this.getLog()}
          onClose={() => {
            this.setState({ showLog: !this.state.showLog })
          }}
          size='large'
        />
        <Popup
          open={this.state.showLogDetail}
          body={this.getLogDetails()}
          onClose={() => this.toggleLogDetails()}
          size='small'
        />
      </>
    )
  }
}
ProductView.propTypes = {
  match: PropTypes.object,
  route: PropTypes.object
}

export default withRouter(ProductView)
