import PropTypes from 'prop-types'
import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export const BreadCrumb = props => {
  if (props.route && props.route.breadCrumbs) {
    return (
      <Breadcrumb size='large' style={{ marginTop: 22, marginBottom: 22 }}>
        {props.route.breadCrumbs.map((breadCrumb, index) => (
          <span key={index}>
            {index < props.route.breadCrumbs.length - 1 && (
              <Breadcrumb.Section link as={Link} to={breadCrumb.path}>
                {breadCrumb.name}
              </Breadcrumb.Section>
            )}
            {index >= props.route.breadCrumbs.length - 1 && (
              <Breadcrumb.Section active>{breadCrumb.name}</Breadcrumb.Section>
            )}
            {index < props.route.breadCrumbs.length - 1 && (
              <Breadcrumb.Divider icon='right arrow' />
            )}
          </span>
        ))}
      </Breadcrumb>
    )
  } else {
    return <div style={{ marginTop: 12 }} />
  }
}

BreadCrumb.propTypes = {
  route: PropTypes.shape({
    breadCrumbs: PropTypes.array
  })
}
