import React from 'react'
import { Modal } from 'semantic-ui-react'

export const withModal = WrappedComponent => {
  return class extends React.Component {
    state = {
      isModalOpen: false,
      header: '',
      content: ''
    }

    closeModal = value => {
      this.setState({ isModalOpen: false })
    }

    openModal = value => {
      this.setState({ isModalOpen: true })
    }

    setModalInfo = (header, content) => {
      this.setState({ header, content })
    }

    renderModal = () => {
      const { isModalOpen, header, content } = this.state

      return (
        <Modal dimmer='blurring' open={isModalOpen} style={{ width: '45rem' }}>
          <Modal.Header>{header}</Modal.Header>
          <Modal.Content image>
            <Modal.Description>{content}</Modal.Description>
          </Modal.Content>
        </Modal>
      )
    }

    render () {
      return (
        <WrappedComponent
          modalState={this.state}
          closeModal={this.closeModal}
          openModal={this.openModal}
          renderModal={this.renderModal}
          setModalInfo={this.setModalInfo}
          {...this.props}
        />
      )
    }
  }
}
