import React, { useEffect, useState } from 'react'
import { Button, Form, Grid, Input, Modal } from 'semantic-ui-react'
import './WarehousePOAddEdit.css'

const POAddProductModal = ({
  isOpen,
  setIsOpen,
  productLoaded,
  btnActionCallback
}) => {
  const [productReqQty, setProductReqQty] = useState('')
  const [product, setProduct] = useState(productLoaded)
  const [modalOpen, setModalOpen] = useState(isOpen)

  useEffect(() => {
    setModalOpen(isOpen)
    setProduct(productLoaded)
    return () => {
      setProductReqQty('')
    }
  }, [isOpen, productLoaded])

  const addButton = () => ({
    handler: () => {
      setModalOpen(false)
      const modalCurrentState = {
        productReqQty,
        product,
        modalOpen
      }
      btnActionCallback(modalCurrentState)
    },
    element () {
      return (
        <Button
          primary
          content='Add'
          disabled={productReqQty < 1}
          onClick={e => {
            this.handler()
          }}
        />
      )
    }
  })

  return (
    <Modal
      dimmer='blurring'
      open={modalOpen}
      style={{ width: '45rem' }}
      closeOnDocumentClick
      closeOnEscape
      closeIcon
      onClose={() => {
        setIsOpen(false)
        console.log(modalOpen)
        setProductReqQty('')
      }}
    >
      <Modal.Description>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Grid.Row className='whPOProductQtyModalGridRows'>
                <Grid.Column align='center'>
                  <h1>{`${product.productNumber} - ${product.name}`}</h1>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row align='center'>
                <Grid.Column>
                  <Form.Field
                    id='modalReqQty'
                    name='modalReqQty'
                    control={Input}
                    label=''
                    className='whPOProductQtyModalInput'
                    value={productReqQty}
                    onKeyUp={e => {
                      if (e.keyCode === 13) {
                        addButton().handler()
                      }
                    }}
                    onChange={e => {
                      const qty = e.target.value
                      setProductReqQty(qty)
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='whPOProductQtyModalGridRows'>
                <Grid.Column align='center'>
                  {addButton().element()}
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Description>
    </Modal>
  )
}

export default POAddProductModal
