import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import { withRequest } from '../../../hoc/withRequest'
import ProductsRepository from '../../../repositories/ProductsRepository.js'
import { PAGE_SIZE } from '../../../repositories/variablesRepository'
import AssignItems from '../../common/assignItems'
import { BreadCrumb } from '../breadCrumb'
import ItemList from './ItemList.js'

class AssignedItems extends React.Component {
  state = {
    count: 0,
    showModal: false,
    rows: [],
    selectDefault: [],
    pageNumber: 1,
    searchTerm: ''
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (_.isEqual(this.state, nextState)) {
      return false
    }
    return true
  }

  componentDidMount () {
    this.getProducts({
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      searchTerm: this.state.searchTerm
    })
  }

  onSearch = (field, searchTerm) => {
    const searchBy = {
      pageNumber: 1,
      pageSize: PAGE_SIZE
    }

    if (field && searchTerm) {
      searchBy[field] = searchTerm
    } else {
      delete searchBy.searchTerm
    }

    this.getProducts(searchBy)
    this.setState({ searchTerm })
  }

  getProducts = async (params = {}) => {
    const { id } = this.props.match.params
    if (id) {
      try {
        params = {
          ...params,
          zoneId: `${this.props.prefixZone}${id}`
        }

        const data = await ProductsRepository.filterBy(params)
        let rows = []
        this.setState({ rows: [] })
        if (data.items.length) {
          this.setState({ products: data.items })
          rows = this.marshallResponse(this.props.columnsDefinition, data.items)
          this.setState({ count: data.count })
          this.setState({ rows: rows })
        }
        this.isLoading(false)
        this.resetScroll()

      } catch (e) {
        console.log(e)
      }
    }
  }

  resetScroll = () =>{
    let gridCanvasScroll = document.getElementsByClassName('react-grid-Canvas')[0]
    if (gridCanvasScroll) gridCanvasScroll.scrollLeft = 1
  }
  isLoading = loading => {
    this.setState({ loading })
  }

  setActivePage = page => {
    this.setState({ pageNumber: page })
  }

  marshallResponse = (model, data) => {
    return data.map((item) => {
      let itemDisplayed = {}
      for (const {
        key,
        serverResponseKey,
        defaultValue,
        formatters
      } of model) {
        if (!item[serverResponseKey]) {
          if (!defaultValue) {
            continue
          }
          itemDisplayed[key] = defaultValue
        } else {
          itemDisplayed[key] = item[serverResponseKey]
        }
        if (formatters) {
          for (const formatter of formatters) {
            itemDisplayed[key] = formatter(itemDisplayed[key])
          }
        }
      }
      return itemDisplayed
    })
  }

  getSelectedProducts = async products => {
    await this.setProducts(products, true)
  }

  onFailure = err => {
    console.log(err)
    this.isLoading(false)
  }

  onSuccess = () => {
    this.getProducts({
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      searchTerm: this.state.searchTerm
    })
  }

  setProducts = async (items, is_new) => {
    const { id } = this.props.match.params
    this.setState({assignLoader: true})
    let products = items
    let response
    try {
      response = await ProductsRepository.assignToZone(
        products,
        `${this.props.prefixZone}${id}`
      )
      this.setState({ pageNumber: 1, assignLoader: false })
    } catch (err) {
      this.onFailure(err)
      this.setState({ assignLoader: false })
      return
    }
    this.onSuccess(response)
    if (is_new) this.toggleModal()
  }

  toggleModal = () => {
    this.setState({ getComponent: !this.state.getComponent }, () => {
      this.setState({ showModal: !this.state.showModal })
    })
  }

  secondarySegment = () => {
    return (
      <Button
        primary
        type='button'
        icon='plus'
        content='Add'
        onClick={() => {
          this.toggleModal()
        }}
      />
    )
  }

  onPageChanged = pageNum => {
    this.setState({ pageNumber: pageNum })
    this.fetchData({
      pageNumber: pageNum,
      pageSize: PAGE_SIZE,
      searchTerm: this.state.searchTerm
    })
    this.resetScroll()
  }

  fetchData = async params => {
    const { id } = this.props.match.params
    params = {
      ...params,
      zoneId: `${this.props.prefixZone}${id}`
    }
    const data = await ProductsRepository.filterBy(params)
    let rows = []
    this.setState({ rows: [] })
    if (data.items.length) {
      this.setState({ products: data.items })
      rows = this.marshallResponse(this.props.columnsDefinition, data.items)
      this.setState({ count: data.count })
      this.setState({ rows: rows })
    }
  }

  render () {
    return (
      <>
        {this.props.match.params.id ? (
          <>
            <Dimmer active={this.state.loading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <ItemList
              breadCrumb={<BreadCrumb route={this.props.route} />}
              columns={this.props.columnsDefinition}
              rows={this.state.rows}
              rowCount={this.state.rows.length}
              totalRows={this.state.count}
              activePage={this.state.pageNumber}
              onPageChanged={this.onPageChanged}
              enableShiftSelect={false}
              totalPages={Math.ceil(Math.abs(this.state.count) / PAGE_SIZE)}
              onSearch={this.onSearch}
              searchTerm={this.state.searchTerm}
              toggleModal={this.toggleModal}
              secondarySegment={this.secondarySegment()}
              isLoading={this.isLoading}
              setActivePage={this.setActivePage}
              getProducts={this.getProducts}
              zoneType={this.props.prefixZone}
              showSearch
            />
            {this.state.getComponent ? (
              <>
                <AssignItems
                  isLoading={this.state.assignLoader}
                  key={this.state.uniqueId}
                  onRef={ref => (this.AssignItemsRef = ref)}
                  type={this.props.prefixZone}
                  id={this.props.match.params.id}
                  selectDefault={this.state.selectDefault}
                  open={this.state.showModal}
                  disabledButton={!this.state.showModal}
                  getSelectedProducts={this.getSelectedProducts}
                  toggleModal={this.toggleModal}
                />
              </>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </>
    )
  }
}

AssignedItems.propTypes = {
  match: PropTypes.object,
  route: PropTypes.any,
  itemId: PropTypes.string,
  prefixZone: PropTypes.string,
  zoneRepository: PropTypes.any,
  addLink: PropTypes.string,
  columnsDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      width: PropTypes.number,
      sortable: PropTypes.bool
    })
  ).isRequired,
  responseModel: PropTypes.any
}

export default withRouter(withRequest(AssignedItems))
