import RequestHandler, { stringifyQueryParams } from './HttpRequestsHandler'
const basePath = '/inventory-zone'

const filterBy = params => {
  return RequestHandler.get(`${basePath}${stringifyQueryParams(params)}`)
}

const getAll = () => {
  return RequestHandler.get(basePath)
}

const get = id => {
  return RequestHandler.get(`${basePath}/${id}`)
}

const getProducts = (url, id) => {
  return RequestHandler.get(`${basePath}/${url}/${id}`)
}

const save = inventoryZone => {
  return RequestHandler.post(basePath, inventoryZone)
}

export default {
  filterBy,
  get,
  getAll,
  save,
  getProducts
}
