import React from 'react'
import { MasterDashboard } from '../../components/modules/master/dashboard/MasterDashboard'
import NotFound from '../../containers/notFoundPage/NotFound'
import InventoryZonesRoutes from './InventoryZonesRoutes'
import LocationsRoutes from './LocationsRoutes'
import locationTypesRoutes from './LocationTypesRoutes'
import PricingZonesRoutes from './PricingZonesRoutes'
import ProductsRoutes from './ProductsRoutes'
import ProductZonesRoutes from './ProductZonesRoutes'
import ReplenishmentRoutes from './ReplenishmentRoutes'

export const MASTER_DASHBOARD_LINK = '/'
export const NOT_FOUND_LINK = '/404'

const masterLayoutRoutes = [
  {
    path: MASTER_DASHBOARD_LINK,
    pageTitle: 'Home',
    icon: 'home',
    isMenu: true,
    menuMatchingRoutes: [MASTER_DASHBOARD_LINK],
    exact: true,
    component: props => {
      return <MasterDashboard {...props} />
    }
  },
  ...locationTypesRoutes,
  ...PricingZonesRoutes,
  ...ProductZonesRoutes,
  ...InventoryZonesRoutes,
  ...ProductsRoutes,
  ...LocationsRoutes,
  ...ReplenishmentRoutes,
  {
    path: '/*',
    exact: false,
    pageTitle: '404 - Page Not Found',
    component: props => {
      return <NotFound {...props} />
    }
  }
]

export default masterLayoutRoutes
