import PropTypes from 'prop-types'
import React from 'react'
import { Card } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { IN_LOCATION_ITEM_LIST_LINK } from '../../../../routes/locationLayoutRoutes'
import LogoStores from '../../../../media/store_icon.png'

export const LocationCard = ({ locationId, image, header, meta }) => {
  return (
    <Card
      as={Link}
      to={IN_LOCATION_ITEM_LIST_LINK.replace(':locationId', locationId)}
      image={LogoStores}
      header={header}
      meta={meta}
    />
  )
}

LocationCard.propTypes = {
  header: PropTypes.string,
  image: PropTypes.string,
  locationId: PropTypes.string,
  meta: PropTypes.string
}

export default LocationCard
