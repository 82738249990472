import PropTypes from 'prop-types'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useDataFetching from '../../../../../hooks/useDataFetching'
import PricingZoneRepository from '../../../../../repositories/PricingZoneRepository'
import {
  PRICING_ZONES_ADD_LINK,
  PRICING_ZONES_EDIT_LINK
} from '../../../../../routes/masterLayoutRoutes/PricingZonesRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ItemList } from '../../../../common/list'
import CustomSimpleCellFormatter from '../../../../common/list/CustomSimpleCellFormatter'
/**
 * @typedef {import('../../../../../types/grid-column-config').GridColumnConfig} GridColumnConfig
 */

const PricingZoneList = props => {
  const {
    data: pricingZones,
    totalCount,
    page,
    totalPages,
    isLoading,
    onSearch,
    onPageChange,
    qsParams,
    setQsParams
  } = useDataFetching(PricingZoneRepository, {
    orderBy: 'pricingZoneName',
    orderDirection: 'asc'
  })

  const history = useHistory()

  const rows = pricingZones.map(
    ({ pricingZoneName = '', description = '', PK, totalProducts }) => {
      const editUrl = `${PRICING_ZONES_EDIT_LINK}/${PK}`
      const editBtn = (
        <Button
          primary
          as={Link}
          to={editUrl}
          icon='pencil'
          size='mini'
          content='Edit'
        />
      )
      return {
        pricingZoneName,
        description,
        editUrl,
        totalItems: totalProducts,
        editBtn
      }
    }
  )

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={
        /** @type {Array<GridColumnConfig>} */
        [
          { key: 'pricingZoneName', name: 'Name', width: 400, sortable: true },
          { key: 'description', name: 'Description', sortable: true },
          { key: 'totalItems', name: 'Items Included', sortable: true },
          {
            key: 'editBtn',
            width: 110,
            formatter: CustomSimpleCellFormatter
          }
        ]
      }
      isLoading={isLoading}
      rows={rows}
      rowCount={rows.length}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      totalPages={totalPages}
      onSortChanged={(sortColumn, sortDirection) => {
        if (sortDirection === 'NONE') {
          setQsParams({
            ...qsParams,
            orderBy: 'id',
            orderDirection: 'ASC'
          })
          return rows
        }
        setQsParams({
          ...qsParams,
          orderBy: sortColumn,
          orderDirection: sortDirection
        })
        return rows
      }}
      onSearch={onSearch}
      onRowDoubleClick={index => {
        history.push(rows[index].editUrl)
      }}
      addLink={PRICING_ZONES_ADD_LINK}
    />
  )
}

PricingZoneList.propTypes = {
  route: PropTypes.any
}

export default PricingZoneList
