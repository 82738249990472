import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from 'react-data-grid'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Confirm,
  Grid,
  Icon,
  Pagination,
  Segment
} from 'semantic-ui-react'
import usePaginatedGridSelection from '../../../hooks/usePaginatedProductsGridSelection'
import ProductsRepository from '../../../repositories/ProductsRepository'
import { sleep } from '../../../utils/index'
import '../assignItems/style.css'
import EmptyRowsView from '../emptyRowsView'
import SearchInput from '../search/SearchInput'

/**
 * @param {Object} props
 */
export const ItemList = props => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [rows, setRows] = useState(props.rows)
  const [selectedIndexes, setSelectedIndexes] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [
    ,
    setPageSelections,
    getSelectedItemsByPage,
    getAllItemsSelectedByProperty,
    resetGridSelection
  ] = usePaginatedGridSelection([])
  let grid

  const resetGrid = useCallback(() => {
    const { rows, activePage } = props

    setActivePage(activePage)
    setRows(rows)
    setSelectedIndexes([])

    const checkAllCheckbox = document.querySelector(
      'input#select-all-checkbox.react-grid-checkbox'
    )
    checkAllCheckbox.checked = false

    const { isSelectedAll, selectedRows } = getSelectedItemsByPage(activePage)
    if (isSelectedAll && rows.length === selectedIndexes.length) {
      checkAllCheckbox.checked = true
    }
    const selIndexes = []
    rows.map((row, rowIdx) => {
      const found = selectedRows.find(
        selRow => selRow.productNumber === row.productNumber
      )
      if (found) {
        selIndexes.push(rowIdx)
      }

      return null
    })
    setSelectedIndexes(selIndexes)
  }, [props, getSelectedItemsByPage, selectedIndexes.length])

  useEffect(() => {
    resetGrid()
  }, [props, resetGrid])

  /**
   * @param {Object} rowModifiedProps
   */
  const onRowsUpdated = ({ fromRow, toRow, updated }) => {
    const updateBackendValue = async columnsConfig => {
      const errors = await columnConfig?.handleChange?.(
        updated[propertyUpdated],
        rows[fromRow]
      )
      if (errors) {
        return errors
      }
    }

    /**
     * Apply the arrays of formatters to the corresponding column base on its configuration
     * @param {Object} columnConfig  Column's configuration
     * @param {any} value Value for the current column
     */
    const applyColumnFormatters = (columnConfig, value) => {
      if (!columnConfig.formatters) {
        return value
      }
      return columnConfig.formatters.map(formatter => {
        return formatter(value)
      })
    }

    /**
     * Executes the update of a given column's value modified
     * @return {Promise<void>}
     */
    const executeUpdate = async () => {
      try {
        const errors = await updateBackendValue(columnConfig)
        if (!errors) {
          const updatedRows = rows.slice()
          for (let i = fromRow; i <= toRow; i++) {
            updated[propertyUpdated] = applyColumnFormatters(
              columnConfig,
              updated[propertyUpdated]
            )
            updatedRows[i] = { ...updatedRows[i], ...updated }
          }
          setRows(updatedRows)
        }
      } catch (err) {}
    }

    if(!updated) return
    const propertyUpdated = Object.keys(updated)[0]
    let columnConfig
    for (const column of props.columns) {
      if (propertyUpdated === column.key) {
        columnConfig = column
      }
    }

    executeUpdate()
  }

  /**
   * @param {_rows<Object>} _rows
   */
  const onRowsSelected = _rows => {
    let _selectedIndexes
    let rowsSelected
    if (_rows > 1) {
      rowsSelected = rows.map(r => {
        return r
      })
    } else {
      _selectedIndexes = selectedIndexes.concat(_rows.map(r => r.rowIdx))
      rowsSelected = _selectedIndexes.map(selectedIdx => {
        return rows[selectedIdx]
      })
    }

    setSelectedIndexes(_selectedIndexes)
    const isSelectedAll =
      rowsSelected.length === rows.length && rowsSelected.length > 0
    if (isSelectedAll) {
      grid.selectAllCheckbox.checked = true
    }

    setPageSelections(activePage, rowsSelected, isSelectedAll)
  }

  /**
   * @param {_rows<Object>} _rows
   */
  const onRowsDeselected = _rows => {
    const rowIndexes = _rows.map(r => r.rowIdx)
    const _selectedIndexes = selectedIndexes.filter(
      i => rowIndexes.indexOf(i) === -1
    )
    const filtered = _selectedIndexes.map(selectedIdx => {
      return rows[selectedIdx]
    })

    const isSelectedAll = filtered.length === rows.length && filtered.length > 0

    setPageSelections(activePage, filtered, isSelectedAll)
    setSelectedIndexes(_selectedIndexes)
  }

  const handleUnassign = async () => {
    const productsToUnassign = getAllItemsSelectedByProperty('productNumber')
    props.isLoading(true)
    resetGridSelection()
    const { id: zoneId } = props.match.params
    await ProductsRepository.unassignFromZone(
      productsToUnassign,
      `${props.zoneType}${zoneId}`
    )
    props.setActivePage(1)
    await sleep(1000)
    props.getProducts()
  }

  return (
    <>
      {props.breadCrumb}
      <Segment.Group>
        <Segment secondary>
          <Grid>
            <Grid.Column width={6}>
              {props.showSearch ? (
                <SearchInput
                  id='searchInput'
                  onSearch={(field, value) => {
                    if (value) {
                      setSelectedIndexes([])
                    }
                    resetGridSelection()
                    props.onSearch(field, value)
                  }}
                />
              ) : (
                <></>
              )}
            </Grid.Column>
            <Grid.Column width={10}>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                  negative
                  type='button'
                  icon='minus'
                  content='Remove'
                  disabled={
                    getAllItemsSelectedByProperty('productNumber').length === 0
                  }
                  onClick={() => {
                    setConfirmOpen(true)
                  }}
                />
                {props.secondarySegment ? props.secondarySegment : <></>}
              </div>
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment style={{ padding: 0 }} className='listing-grid'>
          <ReactDataGrid
            rowKey='productNumber'
            rowSelection={{
              showCheckbox: true,
              enableShiftSelect: true,
              onRowsSelected: onRowsSelected,
              onRowsDeselected: onRowsDeselected,
              selectBy: {
                indexes: selectedIndexes
              }
            }}
            ref={node => (grid = node)}
            columns={props.columns}
            rowGetter={i => rows[i]}
            rowsCount={props.rowCount}
            emptyRowsView={EmptyRowsView}
            editable
            enableCellSelect
            onGridRowsUpdated={onRowsUpdated}
            onGridSort={(sortColumn, sortDirection) => {
              setRows(props.onSortChanged(sortColumn, sortDirection))
            }}
            onRowDoubleClick={props.onRowDoubleClick}
          />
        </Segment>
        <Segment secondary textAlign='right'>
          <Pagination
            activePage={activePage}
            ellipsisItem={{
              content: <Icon name='ellipsis horizontal' />,
              icon: true
            }}
            firstItem={{
              content: <Icon name='angle double left' />,
              icon: true
            }}
            lastItem={{
              content: <Icon name='angle double right' />,
              icon: true
            }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            totalPages={props.totalPages}
            onPageChange={(event, { activePage }) => {
              props.onPageChanged(activePage)
            }}
          />
        </Segment>
      </Segment.Group>
      <Confirm
        open={confirmOpen}
        content='Are you sure you want to unassign the selected products?'
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          handleUnassign()
          setConfirmOpen(false)
        }}
      />
    </>
  )
}

ItemList.propTypes = {
  activePage: PropTypes.number,
  breadCrumb: PropTypes.element,
  columns: PropTypes.array,
  getProducts: PropTypes.func,
  onPageChanged: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onSearch: PropTypes.func,
  onSortChanged: PropTypes.func,
  rowCount: PropTypes.number,
  rows: PropTypes.array,
  secondarySegment: PropTypes.any,
  totalPages: PropTypes.number,
  totalRows: PropTypes.number
}

export default withRouter(ItemList)
