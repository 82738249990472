import RequestHandler, { stringifyQueryParams } from './HttpRequestsHandler'
const basePath = '/location'

const filterBy = params => {
  return RequestHandler.get(`${basePath}${stringifyQueryParams(params)}`)
}

const getAll = () => {
  return RequestHandler.get(basePath)
}

const get = id => {
  return RequestHandler.get(`${basePath}/${id}`)
}

const save = location => {
  return RequestHandler.post(basePath, location)
}

export default {
  getAll,
  save,
  filterBy,
  get
}
