import PropTypes from 'prop-types'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useDataFetching from '../../../../../hooks/useDataFetching'
import LocationTypesRepository from '../../../../../repositories/LocationTypesRepository'
import {
  LOCATION_TYPES_ADD_LINK,
  LOCATION_TYPES_EDIT_LINK
} from '../../../../../routes/masterLayoutRoutes/LocationTypesRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ItemList } from '../../../../common/list'
import CustomSimpleCellFormatter from '../../../../common/list/CustomSimpleCellFormatter'

const LocationTypeList = props => {
  const {
    data: locationZones,
    totalCount,
    page,
    totalPages,
    isLoading,
    onSearch,
    onPageChange,
    qsParams,
    setQsParams
  } = useDataFetching(LocationTypesRepository, {
    orderBy: 'locationTypeName',
    orderDirection: 'asc'
  })

  const history = useHistory()

  const rows = locationZones.map(
    ({ locationZoneName = '', description = '', PK }) => {
      const editUrl = `${LOCATION_TYPES_EDIT_LINK}/${PK}`
      const editBtn = (
        <Button
          primary
          as={Link}
          to={editUrl}
          icon='pencil'
          size='mini'
          content='Edit'
        />
      )
      return {
        locationTypeName: locationZoneName,
        description,
        editUrl,
        editBtn
      }
    }
  )

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={[
        { key: 'locationTypeName', name: 'Name', width: 500, sortable: true },
        { key: 'description', name: 'Description', sortable: true },
        {
          key: 'editBtn',
          width: 110,
          formatter: CustomSimpleCellFormatter
        }
      ]}
      isLoading={isLoading}
      rows={rows}
      rowCount={rows.length}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      totalPages={totalPages}
      onSortChanged={(sortColumn, sortDirection) => {
        if (sortDirection === 'NONE') {
          setQsParams({
            ...qsParams,
            orderBy: 'id',
            orderDirection: 'ASC'
          })
          return rows
        }
        setQsParams({
          ...qsParams,
          orderBy: sortColumn,
          orderDirection: sortDirection
        })
        return rows
      }}
      onSearch={onSearch}
      onRowDoubleClick={index => {
        history.push(rows[index].editUrl)
      }}
      addLink={LOCATION_TYPES_ADD_LINK}
    />
  )
}

LocationTypeList.propTypes = {
  route: PropTypes.any
}

export default LocationTypeList
