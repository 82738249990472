import RequestHandler, { stringifyQueryParams } from './HttpRequestsHandler'
const basePath = '/product-zone'

const filterBy = params => {
  return RequestHandler.get(`${basePath}${stringifyQueryParams(params)}`)
}

const getAll = () => {
  return RequestHandler.get(basePath)
}

const get = id => {
  return RequestHandler.get(`${basePath}/${id}`)
}

const save = productZone => {
  return RequestHandler.post(basePath, productZone)
}

const getProducts = (url, id) => {
  return RequestHandler.get(`${basePath}/${url}/${id}`)
}

const removeProduct = item => {
  return RequestHandler.remove(basePath, item)
}

const PricingZoneRepository = {
  get,
  getAll,
  save,
  getProducts,
  filterBy,
  removeProduct
}

export default PricingZoneRepository
