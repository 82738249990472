import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react'
import useDataFetching from '../../../hooks/useDataFetching'
import ProductsRepository from '../../../repositories/ProductsRepository'
import { BreadCrumb } from '../breadCrumb'
import { ItemList } from './ItemList'
import '../../../styles/ModalAssignStyles.css'

export const AssignItems = props => {
  const [selectedProducts, setSelectedProducts] = useState([])
  const [disabledButton, setDisabledButton] = useState(true)
  const [loadAdd, setLoadAdd] = useState(false)

  const {
    data: products,
    totalCount,
    page,
    totalPages,
    isLoading,
    onSearch,
    onPageChange
  } = useDataFetching(ProductsRepository, {
    zoneId: props.type + props.id,
    isExcludedZone: true
  })

  const getProducts = products_ => {
    setSelectedProducts([...products_])
  }

  useEffect(() => {
    if (document.getElementById('select-all-checkbox')) {
      document.getElementById('select-all-checkbox').checked = false
    }
  }, [])

  // TODO: set the correct information for these products
  const rows = products.map(({ productName = '', PK, itemsIncluded = [] }) => ({
    productNumber: PK,
    productName,
    size: 10,
    form: 'Caps',
    potency: '250 mg',
    PK
  }))

  return (
    <>
      <Modal
        dimmer='blurring'
        open={props.open}
        closeOnDocumentClick
        closeIcon
        onClose={() => props.toggleModal()}
      >
        <Modal.Header>Products</Modal.Header>
        <Modal.Content>
          <Dimmer active={isLoading || props.isLoading} inverted>
            <Loader />
          </Dimmer>
          <ItemList
            setDisabledButton={setDisabledButton}
            getProducts={getProducts}
            breadCrumb={<BreadCrumb route={props.route} />}
            columns={[
              { key: 'productNumber', name: 'Product #', width: 75 },
              { key: 'productName', name: 'Name', width: 200 },
              { key: 'size', name: 'Size' },
              { key: 'form', name: 'Form', width: 150 },
              { key: 'potency', name: 'Potency', width: 150 }
            ]}
            rows={rows}
            rowCount={rows.length}
            totalRows={totalCount}
            onPageChanged={onPageChange}
            activePage={page}
            totalPages={totalPages}
            onSortChanged={(sortColum, sortDirection) => {
              return rows
            }}
            onSearch={onSearch}
            showSearch
          />
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
            disabled={!selectedProducts.length || disabledButton}
            type='button'
            primary
            onClick={() => {
              setDisabledButton(true)
              setLoadAdd(true)
              props.getSelectedProducts(selectedProducts)
            }}
          >
            Add Selection
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

AssignItems.propTypes = {
  getSelectedProducts: PropTypes.func,
  id: PropTypes.string,
  open: PropTypes.bool,
  route: PropTypes.string,
  toggleModal: PropTypes.func,
  type: PropTypes.string
}

export default React.memo(AssignItems)
