import validator from 'validator'

const validateProductNumber = (productNumber, errors) => {
  if (validator.isEmpty(productNumber)) {
    errors.productNumber = 'This field cannot be blank.'
  }
}

const validateProductName = (productName, errors) => {
  if (validator.isEmpty(productName)) {
    errors.productName = 'This field cannot be blank.'
  }
}

const validateBrand = (brand, errors) => {
  if (validator.isEmpty(brand)) {
    errors.brand = 'This field cannot be blank.'
  }
}

const validateUpcCode = (upcCode, errors) => {
  if (validator.isEmpty(upcCode)) {
    errors.upcCode = 'This field cannot be blank.'
  }
}

const validateCategory = (category, errors) => {
  if (validator.isEmpty(category)) {
    errors.category = 'This field cannot be blank.'
  }
}

const validateCost = (cost, errors) => {
  if (validator.isEmpty(cost)) {
    errors.cost = 'This field cannot be blank.'
  }
}

const validateMsrp = (msrp, errors) => {
  if (validator.isEmpty(msrp)) {
    errors.msrp = 'This field cannot be blank.'
  }
}

const validatePrice = (price, errors) => {
  if (validator.isEmpty(price)) {
    errors.price = 'This field cannot be blank.'
  }
}

const validateProductTaxCode = (productTaxCode, errors) => {
  if (validator.isEmpty(productTaxCode)) {
    errors.productTaxCode = 'This field cannot be blank.'
  }
}

const validateForm = (form, errors) => {
  if (validator.isEmpty(form)) {
    errors.form = 'This field cannot be blank.'
  }
}

const validateSize = (size, errors) => {
  if (validator.isEmpty(size)) {
    errors.size = 'This field cannot be blank.'
  }
}

const validatePotency = (potency, errors) => {
  if (validator.isEmpty(potency)) {
    errors.potency = 'This field cannot be blank.'
  }
}

const validateReplenishmentType = (replenishmentType, errors) => {
  if (validator.isEmpty(replenishmentType)) {
    errors.replenishmentType = 'This field cannot be blank.'
  }
}

const validateAttributes = (attributes, errors) => {
  if (validator.isEmpty(attributes)) {
    errors.attributes = 'This field cannot be blank.'
  }
}

export const locationValidators = {
  validateProductNumber,
  validateProductName,
  validateBrand,
  validateUpcCode,
  validateCategory,
  validateCost,
  validateMsrp,
  validatePrice,
  validateProductTaxCode,
  validateForm,
  validateSize,
  validatePotency,
  validateReplenishmentType,
  validateAttributes
}

export default (itemFields, errors) => {
  for (const fieldName in itemFields) {
    const validator = locationValidators[`validate${fieldName.ucfirst()}`]
    if (validator) {
      validator(itemFields[fieldName], errors)
    }
  }
  return errors
}
