import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Message, Segment } from 'semantic-ui-react'

const FormTemplate = props => {
  return (
    <Form
      onSubmit={e => {
        if (
          document.activeElement.id === 'searchInput' ||
          document.activeElement.id === 'search_btn'
        ) {
          return
        }
        props.handleSubmit()
      }}
    >
      {props.breadCrumb}
      <Segment.Group raised>
        <Segment secondary>{props.header}</Segment>
        <Segment loading={props.isSubmitting || props.loading}>
          {props.errors.global && (
            <Message
              error
              style={{ display: 'block' }}
              header='Error'
              content={props.errors.global}
            />
          )}
          {props.form(
            props.values,
            props.handleChange,
            props.handleBlur,
            props.errors,
            props.setFieldValue,
            props.setFieldTouched,
            props.touched
          )}
          {props.assignedItems ? props.assignedItems : <></>}
        </Segment>
        <Segment secondary style={{ overflow: 'hidden' }}>
          <Button.Group floated={props.cancelButton ? 'right' : 'left'}>
            <Button
              as={Link}
              to={props.cancel}
              disabled={props.isSubmitting}
              labelPosition='left'
              icon='arrow left'
              content='Back'
            />
          </Button.Group>
          <Button.Group floated='right'>
            {!props.cancelButton ? (
              <Button
                primary
                type='submit'
                disabled={props.isSubmitting}
                labelPosition='right'
                icon='save'
                content='Save'
              />
            ) : null}
          </Button.Group>
        </Segment>
      </Segment.Group>
    </Form>
  )
}

export const EditForm = props => {
  const [initialValues, setInitialValues] = useState(props.initialValues)
  useEffect(() => {
    setInitialValues(props.initialValues)
  }, [props.initialValues, props.loading])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={values => {
        if (props.validate) {
          return props.validate(values)
        }
        return []
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        if (props.onSubmit) {
          props.onSubmit(values, setSubmitting, setErrors)
        }
      }}
      children={childrenProps => {
        return (
          <FormTemplate
            {...childrenProps}
            loading={props.loading}
            breadCrumb={props.breadCrumb}
            header={props.header}
            form={props.form}
            assignedItems={props.assignedItems}
            cancel={props.cancel}
            cancelButton={props.cancelButton}
          />
        )
      }}
    />
  )
}

EditForm.propTypes = {
  assignedItems: PropTypes.element,
  breadCrumb: PropTypes.element,
  cancel: PropTypes.string,
  form: PropTypes.func,
  header: PropTypes.node,
  initialValues: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  loading: PropTypes.bool,
  cancelButton: PropTypes.bool
}
