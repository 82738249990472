import React, { useContext, useState } from 'react'
import { Accordion, Grid, Icon, Label } from 'semantic-ui-react'
import PurchaseOrderContext from '../../../../context/PurchaseOrderContext'
import ShipmentsGrid from './ShipmentsGrid'

export const SHIPMENT_STATUSES = {
  open: { key: 'open', label: 'Open', labelColor: 'blue' },
  closed: { key: 'closed', label: 'Received', labelColor: 'grey' },
  partially: { key: 'partially', label: 'Partially', labelColor: 'orange' }
}

const WarehousePOShipments = () => {
  const [activeIndex, setActiveIndex] = useState()
  const { purchaseOrder } = useContext(PurchaseOrderContext)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const renderAccordion = shipments => {
    return shipments.map((shipment, index) => {
      return (
        <React.Fragment key={`${shipment.id}`}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <Label
              color={SHIPMENT_STATUSES[shipment.status].labelColor}
              content={SHIPMENT_STATUSES[shipment.status].label}
              style={{ width: '5.5rem', textAlign: 'center' }}
            />
            <Icon name='dropdown' />
            {shipment.reference}
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === index}
            className='listing-grid'
          >
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <ShipmentsGrid
                    key={`${shipment.reference}-grid`}
                    shipment={shipment}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </React.Fragment>
      )
    })
  }

  return (
    <Accordion className='shipmentsAccordion' fluid styled>
      {renderAccordion(purchaseOrder.shipments)}
    </Accordion>
  )
}

export default React.memo(WarehousePOShipments)
