import React from 'react'
import { Input } from 'semantic-ui-react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Editor extends React.Component {

  constructor (props) {
    super(props);
    this.state = {txt: ''}
  }

  getValue () {
    if (this.state.txt) return {[this.props.target]: this.state.txt}
  }

  getInputNode () {
    return ReactDOM.findDOMNode(this).getElementsByTagName('input')[0]
  }

  handleChangeComplete = (ev, txt) => {
    if (txt.value.match('^[0-9]+$') !== null || txt.value === '') this.setState({txt: txt.value})
  };

  render () {
    return (
      <Input type='text' value={this.state.txt} onChange={this.handleChangeComplete}/>
    );
  }
}
Editor.propTypes = {
  target: PropTypes.string
}

export default Editor
