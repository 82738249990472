import { Auth } from 'aws-amplify'
import React from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Grid, Image, Menu } from 'semantic-ui-react'
import LogoWide from '../../media/logo_wide.png'
import AppContainerRoutes from '../../routes/appContainerRoutes'

const NavBar = () => {
  return (
    <>
      <Grid.Row className='nav-section' id='main-top-navbar'>
        <Grid.Column>
          <Menu secondary className='nav-bar'>
            <Menu.Menu position='left'>
              <Menu.Item>
                <Link to='/'>
                  <Image src={LogoWide} />
                </Link>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position='right'>
              <Menu.Item>
                <Button
                  basic
                  onClick={() => {
                    Auth.signOut()
                      .then(data => console.log(data))
                      .catch(err => console.log(err))
                  }}
                >
                  Sign Out
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default () => {
  return (
    <Router>
      <Grid divided stackable>
        <NavBar />
        {AppContainerRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.component route={route} />}
          />
        ))}
      </Grid>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Router>
  )
}
