import validator from 'validator'
import { ERROR_BLANK } from '../../../../common/errors/Errors'

const validateLocationName = (locationName, errors) => {
  if (validator.isEmpty(locationName)) {
    errors.locationName = ERROR_BLANK
  }
}

const validateDescription = (description, errors) => {
  if (validator.isEmpty(description)) {
    errors.description = ERROR_BLANK
  }
}

const validateBusinessName = (businessName, errors) => {
  if (validator.isEmpty(businessName)) {
    errors.businessName = ERROR_BLANK
  }
}

const validateAddressLine1 = (addressLine1, errors) => {
  if (validator.isEmpty(addressLine1)) {
    errors.addressLine1 = ERROR_BLANK
  }
}

const validateLocality = (locality, errors) => {
  if (validator.isEmpty(locality)) {
    errors.locality = ERROR_BLANK
  }
}

const validatePostalCode = (postalCode, errors) => {
  if (validator.isEmpty(postalCode)) {
    errors.postalCode = ERROR_BLANK
  }

  if (!validator.isNumeric(postalCode)) {
    errors.postalCode = 'Postal code should be numbers'
  }

  if (postalCode.length !== 5) {
    errors.postalCode = 'Postal code should be 5 chars size'
  }
}

const validateType = (type, errors) => {
  if (!type) {
    errors.type = ERROR_BLANK
  }
}

const validateTimezone = (timezone, errors) => {
  if (!timezone) {
    errors.timezone = ERROR_BLANK
  }
}

const validateLocationType = (locationType, errors) => {
  if (!locationType) {
    errors.locationType = ERROR_BLANK
  }
}

const validatePricingZone = (pricingZone, errors) => {
  if (!pricingZone) {
    errors.pricingZone = ERROR_BLANK
  }
}

const validateProductZone = (productZone, errors) => {
  if (!productZone) {
    errors.productZone = ERROR_BLANK
  }
}

const validateInventoryZone = (inventoryZone, errors) => {
  if (!inventoryZone) {
    errors.inventoryZone = ERROR_BLANK
  }
}

const validateAdministrativeDistrictLevel1 = (
  administrativeDistrictLevel1,
  errors
) => {
  if (!administrativeDistrictLevel1) {
    errors.administrativeDistrictLevel1 = ERROR_BLANK
  }
}

const validateWarehouse = (warehouse, errors) => {
  if (!warehouse) {
    errors.warehouse = ERROR_BLANK
  }
}

export const locationValidators = {
  validateLocationName,
  validateDescription,
  validateBusinessName,
  validateAddressLine1,
  validateLocality,
  validatePostalCode,
  validateType,
  validateTimezone,
  validateLocationType,
  validatePricingZone,
  validateProductZone,
  validateInventoryZone,
  validateAdministrativeDistrictLevel1,
  validateWarehouse
}

export default (itemFields, errors) => {
  for (const fieldName in itemFields) {
    const validator = locationValidators[`validate${fieldName.ucfirst()}`]
    if (validator) {
      validator(itemFields[fieldName], errors)
    }
  }
  return errors
}
