import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import ReactDataGrid from 'react-data-grid'
import { withRouter } from 'react-router-dom'
import { Button, Grid, Icon, Pagination, Segment } from 'semantic-ui-react'
import usePaginatedGridSelection from '../../../hooks/usePaginatedProductsGridSelection'
import '../assignItems/style.css'
import EmptyRowsView from '../emptyRowsView'
import SearchInput from '../search/SearchInput'

/**
 * @param {Object} props
 */
export const ItemList = props => {
  /** @type {[Array, Function]} */
  const [rows, setRows] = useState(props.rows)
  /** @type {[Array, Function]} */
  const [selectedIndexes, setSelectedIndexes] = useState([])
  const [activePage, setActivePage] = useState(1)
  const grid = useRef()
  const [
    ,
    setPageSelections,
    getSelectedItemsByPage,
    getAllItemsSelectedByProperty,
    resetGridSelection
  ] = usePaginatedGridSelection()

  const resetGrid = useCallback(() => {
    const { rows, activePage } = props

    setActivePage(activePage)
    setRows(rows)
    setSelectedIndexes([])

    /** @type {HTMLInputElement|null} */
    const checkAllCheckbox = document.querySelector(
      '.dataGridAssign_ input#select-all-checkbox.react-grid-checkbox'
    )

    const { isSelectedAll, selectedRows } = getSelectedItemsByPage(activePage)
    if (checkAllCheckbox) {
      checkAllCheckbox.checked = false
      if (isSelectedAll && rows.length === selectedIndexes.length) {
        checkAllCheckbox.checked = true
      }
    }

    const selIndexes = []
    rows.map((row, rowIdx) => {
      const found = selectedRows.find(
        selRow => selRow.productNumber === row.productNumber
      )
      if (found) {
        selIndexes.push(rowIdx)
      }
      return null
    })
    setSelectedIndexes(selIndexes)
  }, [props, getSelectedItemsByPage, selectedIndexes.length])

  useEffect(() => {
    resetGrid()
  }, [resetGrid])

  /**
   * @param {Array} _rows
   */
  const onRowsSelected = _rows => {
    let _selectedIndexes
    let rowsSelected

    _selectedIndexes = selectedIndexes.concat(_rows.map(r => r.rowIdx))
    rowsSelected = _selectedIndexes.map(selectedIdx => {
      return rows[selectedIdx]
    })

    setSelectedIndexes(_selectedIndexes)
    const isSelectedAll =
      rowsSelected.length === rows.length && rowsSelected.length > 0
    setPageSelections(activePage, rowsSelected, isSelectedAll)
    props.getProducts(getAllItemsSelectedByProperty('PK'))
    props.setDisabledButton(false)
  }

  /**
   * @param {Array} _rows
   */
  const onRowsDeselected = _rows => {
    const rowIndexes = _rows.map(r => r.rowIdx)
    const _selectedIndexes = selectedIndexes.filter(
      i => rowIndexes.indexOf(i) === -1
    )
    const filtered = _selectedIndexes.map(selectedIdx => {
      return rows[selectedIdx]
    })

    const isSelectedAll = filtered.length === rows.length && filtered.length > 0

    setPageSelections(activePage, filtered, isSelectedAll)
    setSelectedIndexes(_selectedIndexes)

    if (getAllItemsSelectedByProperty('PK').length === 0) {
      props.setDisabledButton(true)
    }
  }

  const scrollToTop = () => {
    const canvas = grid.current.getDataGridDOMNode().querySelector('.react-grid-Canvas')
    if (canvas) canvas.scrollTop = 0
  }

  return (
    <>
      {props.breadCrumb}
      <Segment.Group>
        <Segment secondary>
          <Grid>
            <Grid.Column width={6}>
              {props.showSearch ? (
                <SearchInput
                  onSearch={props.onSearch}
                  onSearchCallback={() => {
                    resetGridSelection()
                  }}
                />
              ) : (
                <></>
              )}
            </Grid.Column>
            <Grid.Column floated='right'>
              <Button.Group floated='right'>
                {props.secondarySegment ? props.secondarySegment : <></>}
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment style={{ padding: 0 }} className='listing-grid'>
          <div className='dataGridAssign_'>
            <ReactDataGrid
              ref={grid}
              rowKey='productNumber'
              rowSelection={{
                showCheckbox: true,
                enableShiftSelect: true,
                onRowsSelected: onRowsSelected,
                onRowsDeselected: onRowsDeselected,
                selectBy: {
                  indexes: selectedIndexes
                }
              }}
              columns={props.columns}
              rowGetter={i => rows[i]}
              rowsCount={props.rowCount}
              emptyRowsView={EmptyRowsView}
              onGridSort={(sortColumn, sortDirection) => {
                setRows(props.onSortChanged(sortColumn, sortDirection))
              }}
            />
          </div>
        </Segment>
        <Segment secondary textAlign='right'>
          <Pagination
            activePage={activePage}
            ellipsisItem={{
              content: <Icon name='ellipsis horizontal' />,
              icon: true
            }}
            firstItem={{
              content: <Icon name='angle double left' />,
              icon: true
            }}
            lastItem={{
              content: <Icon name='angle double right' />,
              icon: true
            }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            totalPages={props.totalPages}
            onPageChange={(event, { activePage }) => {
              scrollToTop()
              props.onPageChanged(activePage)
            }}
          />
        </Segment>
      </Segment.Group>
    </>
  )
}

ItemList.propTypes = {
  activePage: PropTypes.number,
  breadCrumb: PropTypes.element,
  columns: PropTypes.array,
  getProducts: PropTypes.func,
  onPageChanged: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onSearch: PropTypes.func,
  onSortChanged: PropTypes.func,
  rowCount: PropTypes.number,
  rows: PropTypes.array,
  secondarySegment: PropTypes.any,
  totalPages: PropTypes.number,
  totalRows: PropTypes.number,
  showSearch: PropTypes.bool,
  setDisabledButton: PropTypes.func,
  showCheckbox: PropTypes.bool,
  secondary: PropTypes.element,
  minHeight: PropTypes.number
}

export default withRouter(ItemList)
