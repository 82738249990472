import { useEffect, useState } from 'react'

/**
 * @typedef {import('../types/grid-column-config').GridColumnConfig} GridColumnConfig
 */

/**
 *
 * @param {Array<GridColumnConfig>} model
 * @param {Object} data
 * @returns {Array}
 */
const useMarshallResponse = (model, data) => {
  const [localData, setData] = useState([])

  useEffect(() => {
    if (!data) {
      return
    }

    const _data = data.map(item => {
      const itemDisplayed = {}
      itemDisplayed.id = item.id
      for (const {
        key,
        serverResponseKey,
        defaultValue,
        formatters
      } of model) {
        if (!item[serverResponseKey]) {
          if (!defaultValue) {
            continue
          }
          itemDisplayed[key] = defaultValue
        } else {
          itemDisplayed[key] = item[serverResponseKey]
        }

        if (formatters) {
          for (const formatter of formatters) {
            itemDisplayed[key] = formatter(itemDisplayed[key])
          }
        }
      }

      return itemDisplayed
    })
    setData(_data)
  }, [model, data])

  return localData
}

export default useMarshallResponse
