import PropTypes from 'prop-types'
import React from 'react'
import { Switch, withRouter } from 'react-router'
import { Route } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import LocationLayoutSidebar from '../../components/modules/location/sidebar/LocationLayoutSidebar'
import { LocationLayoutRoutes } from '../../routes/locationLayoutRoutes'

const LocationLayout = props => {
  return (
    <Grid.Row className='sidebar-section'>
      <Grid.Column className='sidebar'>
        <LocationLayoutSidebar />
      </Grid.Column>
      <Grid.Column className='content-section'>
        <Switch>
          {LocationLayoutRoutes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component {...props} route={route} />
            </Route>
          ))}
        </Switch>
      </Grid.Column>
    </Grid.Row>
  )
}

LocationLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.string
    })
  })
}
export default withRouter(LocationLayout)
