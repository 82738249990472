import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactDataGrid from 'react-data-grid'
import { Link } from 'react-router-dom'
import { Button, Grid, Icon, Pagination, Segment } from 'semantic-ui-react'
import EmptyRowsView from '../emptyRowsView'
import SearchInput from '../search/SearchInput'

export const ItemList = props => {
  const [rows, setRows] = useState(props.rows)
  const [selectedIndexes, setSelectedIndexes] = useState([0])
  const grid = useRef()

  const scrollToTop = () => {
    const canvas = grid.current
      .getDataGridDOMNode()
      .querySelector('.react-grid-Canvas')
    if (canvas) canvas.scrollTop = 0
  }

  useEffect(() => {
    setRows(props.rows)
    scrollToTop()
  }, [props.rows, props.activePage])

  const renderSearch = () => {
    return props.hideSearch ? (
      <></>
    ) : (
      <SearchInput onSearch={props.onSearch} searchBy={props.searchBy} />
    )
  }

  return (
    <>
      {props.breadCrumb}
      <Segment.Group>
        <Segment secondary>
          <Grid>
            <Grid.Column width={6}>{renderSearch()}</Grid.Column>
            <Grid.Column floated='right'>
              {/* TODO: Remove this button and include it in the topButtons slot to detach this button from this generic component  */}
              <Button.Group floated='right'>
                {!props.showEdit ? (
                  <Button
                    primary
                    as={Link}
                    to={props.addLink}
                    icon='plus'
                    labelPosition='left'
                    content='Add'
                  />
                ) : (
                  <></>
                )}
                {props.topButtons}
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment
          style={{ padding: 0 }}
          className='listing-grid'
          loading={props.isLoading}
        >
          <ReactDataGrid
            ref={grid}
            minHeight={610}
            columns={props.columns}
            rowGetter={i => rows[i]}
            rowsCount={props.rowCount}
            emptyRowsView={EmptyRowsView}
            onGridSort={(sortColumn, sortDirection) => {
              setRows(props.onSortChanged(sortColumn, sortDirection))
            }}
            onRowClick={rowIdx => {
              setSelectedIndexes([...rows, rowIdx])
            }}
            onRowDoubleClick={props.onRowDoubleClick}
            selectAllRenderer={() => {
              return ''
            }}
            rowSelection={{
              showCheckbox: false,
              selectBy: {
                indexes: selectedIndexes
              },
              onRowsSelected: rows => {
                setSelectedIndexes([rows[0].rowIdx])
              }
            }}
          />
        </Segment>
        <Segment secondary textAlign='right'>
          {props.topSegment}
          <Pagination
            disabled={props.totalPages < 2}
            activePage={props.activePage}
            ellipsisItem={{
              content: <Icon name='ellipsis horizontal' />,
              icon: true
            }}
            firstItem={{
              content: <Icon name='angle double left' />,
              icon: true
            }}
            lastItem={{
              content: <Icon name='angle double right' />,
              icon: true
            }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            totalPages={props.totalPages}
            onPageChange={(event, data) => {
              props.onPageChanged(data.activePage)
            }}
          />
        </Segment>
      </Segment.Group>
    </>
  )
}

ItemList.propTypes = {
  activePage: PropTypes.number,
  addLink: PropTypes.string,
  breadCrumb: PropTypes.element,
  columns: PropTypes.array,
  onPageChanged: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onSearch: PropTypes.func,
  onSortChanged: PropTypes.func,
  rowCount: PropTypes.number,
  rows: PropTypes.array,
  totalRows: PropTypes.number,
  isLoading: PropTypes.bool,
  showEdit: PropTypes.bool,
  buttons: PropTypes.element,
  topButtons: PropTypes.element,
  totalPages: PropTypes.number,
  searchBy: PropTypes.string
}
