import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Form, Grid, Input } from 'semantic-ui-react'
import LocationProductContext from '../../../../../context/LocationProductContext'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  ENTITY_PREFIX_LOCATION,
  ENTITY_PREFIX_PRODUCT
} from '../../../../../repositories/variablesRepository'
import '../../../../../styles/FormStyle.css'
import validate from './validators/GeneralViewValidator'
import '../../../../../styles/ProductViewStyle.css'

const InventoryView = ({ match }) => {
  const context = useContext(LocationProductContext)
  const {
    product,
    setLoading,
    setTopMessage,
    setTopErrors,
    resetTopMessages
  } = context

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true)
      resetTopMessages([])
      await ProductsRepository.saveByZone(
        ENTITY_PREFIX_PRODUCT + match.params.productId,
        ENTITY_PREFIX_LOCATION + match.params.locationId,
        values
      )
      setTopMessage('Inventory product data updated successfully')
    } catch (err) {
      setTopErrors([err.message])
      console.error(err.message)
    }
    setLoading(false)
  }

  const validateForm = values => {
    return validate(values, {})
  }

  const displayUndo = (fieldName, values) => {
    return undoLink()
    function undoLink() {
      return (
        <a className='float-left' href='#'>
          <i aria-hidden="true" className="undo big icon"></i></a>
      )
    }
    return null
  }

  return (
    <>
      <Formik
        initialValues={product}
        enableReinitialize
        validate={validateForm}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid columns='equal'>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Field
                      id='locationAvailQty'
                      name='locationAvailQty'
                      control={Input}
                      label='Location Available Quantity'
                      placeholder='Location Available Quantity'
                      readOnly
                      value={values.locationAvailQty || ''}
                      error={
                        touched.locationAvailQty && errors.locationAvailQty
                      }
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Field
                      id='locationDaysAvail'
                      name='locationDaysAvail'
                      control={Input}
                      readOnly
                      label='Location Days Available'
                      placeholder='Location Days Available'
                      value={values.locationDaysAvail || ''}
                      error={
                        touched.locationDaysAvail && errors.locationDaysAvail
                      }
                      onChange={handleChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Field
                      id='monthlyUsage'
                      name='monthlyUsage'
                      control={Input}
                      label='Monthly Usage'
                      placeholder='Monthly Usage'
                      readOnly
                      value={values.monthlyUsage || ''}
                      onChange={handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={6}>
                    <label className='float-left'><b>Minimum Quantity</b></label>
                    { displayUndo('price', values) }
                    <Form.Field
                      id='minimumQuantity'
                      name='minimumQuantity'
                      control={Input}
                      label=''
                      placeholder='Minimum Quantity'
                      value={values.minimumQuantity || ''}
                      error={touched.minimumQuantity && errors.minimumQuantity}
                      onChange={(ev, txt)=>{
                        if(txt.value.match('^[0-9]+$')) handleChange(ev, txt)
                      }}
                      type='text'
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Field
                      id='reorderDays'
                      name='reorderDays'
                      control={Input}
                      label='Reorder Days'
                      placeholder='Reorder Days'
                      value={values.reorderDays || ''}
                      error={touched.reorderDays && errors.reorderDays}
                      onChange={(ev, txt)=>{
                        if(txt.value.match('^[0-9]+$')) handleChange(ev, txt)
                      }}
                      type='text'
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Field
                      id='targetDays'
                      name='targetDays'
                      control={Input}
                      label='Target Days'
                      placeholder='Target Days'
                      value={values.targetDays || ''}
                      error={touched.targetDays && errors.targetDays}
                      onChange={(ev, txt)=>{
                        if(txt.value.match('^[0-9]+$')) handleChange(ev, txt)
                      }}
                      type='text'
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16} align='right'>
                    <Button
                      primary
                      type='submit'
                      labelPosition='right'
                      icon='save'
                      content='Save'
                      readOnly={isSubmitting}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

InventoryView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired
    })
  })
}

export default withRouter(InventoryView)
