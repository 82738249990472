import PropTypes from 'prop-types'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useDataFetching from '../../../../../hooks/useDataFetching'
import LocationsRepository from '../../../../../repositories/LocationsRepository'
import {
  LOCATIONS_ADD_LINK,
  LOCATIONS_EDIT_LINK
} from '../../../../../routes/masterLayoutRoutes/LocationsRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ItemList } from '../../../../common/list'
import CustomSimpleCellFormatter from '../../../../common/list/CustomSimpleCellFormatter'

const LocationsList = props => {
  const {
    data: locationsList,
    totalCount,
    page,
    totalPages,
    isLoading,
    onSearch,
    onPageChange,
    qsParams,
    setQsParams
  } = useDataFetching(LocationsRepository, {
    orderBy: 'locationName',
    orderDirection: 'asc'
  })
  const history = useHistory()

  const rows = locationsList.map(
    ({
      locationName,
      productZoneName = '',
      pricingZoneName = '',
      inventoryZoneName = '',
      isActive = true,
      PK
    }) => {
      const editUrl = `${LOCATIONS_EDIT_LINK}/${PK}`
      const editBtn = (
        <Button
          primary
          as={Link}
          to={editUrl}
          icon='pencil'
          size='mini'
          content='Edit'
        />
      )
      const status = isActive ? 'Active' : 'Inactive'
      return {
        locationName,
        productZoneName,
        pricingZoneName,
        inventoryZoneName,
        status,
        editUrl,
        editBtn
      }
    }
  )

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={[
        { key: 'locationName', name: 'Name', width: 500, sortable: true },
        { key: 'productZoneName', name: 'Product Zone', sortable: true },
        { key: 'pricingZoneName', name: 'Pricing Zone', sortable: true },
        { key: 'inventoryZoneName', name: 'Inventory Zone', sortable: true },
        { key: 'status', name: 'Status' },
        {
          key: 'editBtn',
          width: 110,
          formatter: CustomSimpleCellFormatter
        }
      ]}
      isLoading={isLoading}
      rows={rows}
      rowCount={rows.length}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      totalPages={totalPages}
      onSortChanged={(sortColumn, sortDirection) => {
        if (sortDirection === 'NONE') {
          setQsParams({
            ...qsParams,
            orderBy: 'id',
            orderDirection: 'ASC'
          })
          return rows
        }
        setQsParams({
          ...qsParams,
          orderBy: sortColumn,
          orderDirection: sortDirection
        })
        return rows
      }}
      onSearch={onSearch}
      onRowDoubleClick={index => {
        history.push(rows[index].editUrl)
      }}
      addLink={LOCATIONS_ADD_LINK}
    />
  )
}

LocationsList.propTypes = {
  route: PropTypes.any
}

export default LocationsList
