import React from 'react'
import LocationsAddEdit from '../../components/modules/master/locations/addEdit/LocationsAddEdit'
import LocationsList from '../../components/modules/master/locations/list/LocationsList'

export const LOCATIONS_LISTING_LINK = '/locations/list'
export const LOCATIONS_ADD_LINK = '/locations/add'
export const LOCATIONS_EDIT_LINK = '/locations/edit'

const locationRoutes = [
  {
    path: LOCATIONS_LISTING_LINK,
    pageTitle: 'Locations',
    icon: 'map marker alternate',
    isMenu: true,
    menuMatchingRoutes: [LOCATIONS_LISTING_LINK, LOCATIONS_ADD_LINK],
    exact: true,
    breadCrumbs: [
      {
        name: 'Locations'
      }
    ],
    component: props => <LocationsList {...props} />
  },
  {
    path: LOCATIONS_ADD_LINK,
    pageTitle: 'Add Location',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Locations',
        path: LOCATIONS_LISTING_LINK
      },
      {
        name: 'Add'
      }
    ],
    exact: true,
    component: props => <LocationsAddEdit {...props} />
  },
  {
    path: LOCATIONS_EDIT_LINK + '/:id',
    pageTitle: 'Edit Location',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Location',
        path: LOCATIONS_LISTING_LINK
      },
      {
        name: 'Edit'
      }
    ],
    exact: true,
    component: props => <LocationsAddEdit {...props} />
  }
]

export default locationRoutes
