import React from 'react'

const EmptyRowsView = ({ message = 'No results found' }) => {
  return (
    <div style={{ textAlign: 'center', padding: '100px', fontSize: '20px' }}>
      {message}
    </div>
  )
}

export default EmptyRowsView
