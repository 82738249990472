import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useDataFetching from '../../../../../hooks/useDataFetching'
import ProductZoneRepository from '../../../../../repositories/ProductZoneRepository'
import {
  PRODUCT_ZONES_ADD_LINK,
  PRODUCT_ZONES_EDIT_LINK
} from '../../../../../routes/masterLayoutRoutes/ProductZonesRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ItemList } from '../../../../common/list'
import CustomSimpleCellFormatter from '../../../../common/list/CustomSimpleCellFormatter'

export const ProductZoneList = props => {
  const [showModal, setShowModal] = useState(false)
  const {
    data: productZones,
    totalCount,
    page,
    totalPages,
    isLoading,
    onSearch,
    onPageChange,
    qsParams,
    setQsParams
  } = useDataFetching(ProductZoneRepository, {
    orderBy: 'productZoneName',
    orderDirection: 'asc'
  })

  const history = useHistory()

  const rows = productZones.map(
    ({ productZone = '', description = '', PK, totalProducts }) => {
      const editUrl = `${PRODUCT_ZONES_EDIT_LINK}/${PK}`
      const editBtn = (
        <Button
          primary
          as={Link}
          to={editUrl}
          icon='pencil'
          size='mini'
          content='Edit'
        />
      )
      return {
        productZoneName: productZone,
        description,
        totalItems: totalProducts,
        editUrl,
        editBtn
      }
    }
  )

  function toggleModal () {
    setShowModal(!showModal)
  }

  return (
    <>
      <ItemList
        breadCrumb={<BreadCrumb route={props.route} />}
        columns={[
          { key: 'productZoneName', name: 'Name', width: 350, sortable: true },
          { key: 'description', name: 'Description', sortable: true },
          {
            key: 'totalItems',
            name: 'Items Included',
            width: 150,
            sortable: true
          },
          {
            key: 'editBtn',
            width: 110,
            formatter: CustomSimpleCellFormatter
          }
        ]}
        isLoading={isLoading}
        rows={rows}
        rowCount={rows.length}
        totalRows={totalCount}
        onPageChanged={onPageChange}
        activePage={page}
        totalPages={totalPages}
        onSortChanged={(sortColumn, sortDirection) => {
          if (sortDirection === 'NONE') {
            setQsParams({
              ...qsParams,
              orderBy: 'id',
              orderDirection: 'ASC'
            })
            return rows
          }
          setQsParams({
            ...qsParams,
            orderBy: sortColumn,
            orderDirection: sortDirection
          })
          return rows
        }}
        onSearch={onSearch}
        onRowDoubleClick={index => {
          history.push(rows[index].editUrl)
        }}
        addLink={PRODUCT_ZONES_ADD_LINK}
        toggleModal={toggleModal}
      />
    </>
  )
}

ProductZoneList.propTypes = {
  route: PropTypes.any
}

export default ProductZoneList
