import { Auth } from 'aws-amplify'
import React from 'react'
const withCustomAuthenticator = WrappedComponent => {
  return class extends React.Component {
    state = { isLogin: true }

    // componentDidMount () {
    //   Auth.currentAuthenticatedUser()
    //     .then(res => {
    //       this.setState({ isLogin: true })
    //     })
    //     .catch(() => {
    //       Auth.federatedSignIn()
    //     })
    // }
    render () {
      return this.state.isLogin ? <WrappedComponent {...this.props} /> : <></>
    }
  }
}

export default withCustomAuthenticator
