import PropTypes from 'prop-types'
import React from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

const LoadingScreen = ({ size = 'large', text = 'Loading...' }) => (
  <div>
    <Segment>
      <Dimmer active inverted>
        <Loader inverted size={size}>{text}</Loader>
      </Dimmer>

      <div style={{ height: '150px' }} />
    </Segment>
  </div>
)

LoadingScreen.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string
}

export default LoadingScreen
