const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_POOLS_WEB_CLIENT,
    oauth: {
      domain: process.env.REACT_APP_OAUT_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      responseType: 'token'
    }
  },
  API: {
    endpoints: [
      {
        name: 'mcs',
        endpoint: process.env.REACT_APP_BASE_URL,
        region: process.env.REACT_APP_AWS_PROJECT_REGION
      },
      {
        name: 'transactions',
        endpoint: process.env.REACT_APP_TRANSACTIONS_BASE_URL,
        region: process.env.REACT_APP_AWS_PROJECT_REGION
      },
      {
        name: 'fulfillment',
        endpoint: process.env.REACT_APP_FULFILLMENT_BASE_URL,
        region: process.env.REACT_APP_AWS_PROJECT_REGION
      }
    ]
  }
}

export default config
