import React from 'react'
import { withRouter } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useDataFetching from '../../../../hooks/useDataFetching'
import useMarshallResponse from '../../../../hooks/useMarshallResponse'
import PurchaseOrdersRepository from '../../../../repositories/transactionsApi/PurchaseOrdersRepository'
import { IN_LOCATION_PO_ADD_EDIT } from '../../../../routes/locationLayoutRoutes/LocationLayoutRoutes'
import { IN_LOCATION_WAREHOUSE_PO_LIST_LINK } from '../../../../routes/locationLayoutRoutes/WarehousePORoutes'
import { BreadCrumb } from '../../../common/breadCrumb/BreadCrumb'
import CustomSimpleCellFormatter from '../../../common/list/CustomSimpleCellFormatter'
import { ItemList } from '../../../common/list/ItemList'
import { PRODUCTS_VIEW_LINK } from '../../../../routes/masterLayoutRoutes/ProductsRoutes'

const actionBtnKey = 'actionBtn'

const poStatusEnum = {
  initial: 'Initial',
  shipped: 'Shipped',
  draft: 'Draft',
  closed: 'Closed'
}

const gridModel = [
  {
    key: 'reference',
    name: 'Reference',
    resizable: true,
    defaultValue: '',
    serverResponseKey: 'reference'
  },
  {
    key: 'lastUpdate',
    name: 'Last Update Date',
    resizable: true,
    serverResponseKey: 'lastUpdate'
  },
  {
    key: 'status',
    name: 'Status',
    resizable: true,
    defaultValue: 'not set',
    serverResponseKey: 'status',
    formatters: [value => poStatusEnum[value]]
  },
  {
    key: 'type',
    name: 'Type',
    resizable: true,
    defaultValue: 'not set',
    serverResponseKey: 'type'
  },
  {
    key: actionBtnKey,
    name: 'Action',
    resizable: true,
    formatter: CustomSimpleCellFormatter
  }
]
export const PurchaseOrdersGeneralList = props => {
  const history = useHistory()
  const {
    data,
    totalCount,
    page,
    term,
    totalPages,
    isLoading,
    onSearch,
    onPageChange
  } = useDataFetching(PurchaseOrdersRepository)

  const appendEditButtons = rows => {
    return rows.map(row => {
      return {
        ...row,
        [actionBtnKey]: (
          <Button
            primary
            as={Link}
            to={IN_LOCATION_PO_ADD_EDIT.replace(
              ':locationId',
              props.match.params.locationId
            ).replace(':poId', row.id)}
            size='mini'
            content='Edit'
          />
        )
      }
    })
  }

  const rows = appendEditButtons(useMarshallResponse(gridModel, data))

  const topButtons = () => (
    <Button primary as={Link} to={'#'} content='Create' />
  )

  const bottomButtons = () => (
    <Button.Group floated='left'>
      <Button
        as={Link}
        to={IN_LOCATION_WAREHOUSE_PO_LIST_LINK.replace(
          ':locationId',
          props.match.params.locationId
        )}
        labelPosition='left'
        icon='arrow left'
        content='Back'
      />
    </Button.Group>
  )

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={gridModel}
      showEdit
      isLoading={isLoading}
      rows={rows}
      rowCount={totalCount}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      searchTerm={term}
      totalPages={totalPages ?? 0}
      onSortChanged={(sortColum, sortDirection) => {
        return []
      }}
      onSearch={onSearch}
      searchBy='reference'
      topButtons={topButtons()}
      bottomButtons={bottomButtons()}
      onRowDoubleClick={index => {
        history.push(IN_LOCATION_PO_ADD_EDIT.replace(':locationId', props.match.params.locationId).replace(':poId', rows[index].id))
      }}
    />
  )
}

export default withRouter(PurchaseOrdersGeneralList)
