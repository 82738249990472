import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import ProductsRepository from '../../../../../repositories/ProductsRepository'

const ProductAutocomplete = props => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    const params = props.filterProductsBy ?? { pageSize: 9999 }
    const searchProduct = async () => {
      try {
        const { count, items } = await ProductsRepository.filterBy(params)
        if (count > 0) {
          const products_ = items.map(product => ({
            key: product.PK,
            text: `${product.PK} - ${product.productName}`,
            value: product.PK
          }))
          setProducts(products_)
        }
      } catch (err) {
        console.log(err)
      }
    }
    searchProduct()
  }, [props.defaultSelected, props.filterProductsBy])

  return (
    <Dropdown
      fluid
      search
      selection
      defaultValue={props.defaultSelected}
      options={products}
      openOnFocus={false}
      selectOnNavigation={false}
      onChange={(e, { value }) => {
        props.onSelect(value)
      }}
    />
  )
}

ProductAutocomplete.propTypes = {
  defaultSelected: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  filterProductsBy: PropTypes.object
}

export default ProductAutocomplete
