import { useCallback, useEffect, useState } from 'react'
import { PAGE_SIZE } from '../repositories/variablesRepository'

/**
 * Hook use to make HttpRequest, whenever it state changes it will re render and
 * trigger a new http request through the code inside the useEffect, using its
 * state data. So if you want to create a new request, modify this hook's state i.e: the page,
 * or the search term
 * @param {Object} repository Repository to be used for
 * @param {Object} params params to use via query string
 */
function useDataFetching (repository, params = {}) {
  const initialParams = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    ...params
  }
  const [totalPages, setTotalPages] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [qsParams, setQsParams] = useState(initialParams)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  const memoizedFetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { items, count, totalPages } = await repository.filterBy(qsParams)
      setTotalCount(count)
      setData(items)
      setTotalPages(totalPages)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [repository, qsParams])

  useEffect(() => {
    memoizedFetchData()
  }, [memoizedFetchData])

  /**
   * Change the page number in the hook's state
   * @param {Number} pageNum Pagination number to request
   */
  const onPageChange = async pageNum => {
    setQsParams({
      ...qsParams,
      pageNumber: pageNum
    })
  }

  /**
   * Updates the state of this hook and that causes a rerender which reloads data
   * @param {String} field query parameter to filter i.e ?searchTerm=<text_to_search>
   * @param {String} term term to search
   */
  const onSearch = (field, term) => {
    const searchBy = initialParams
    if (field && term) {
      searchBy[field] = term
    } else {
      delete searchBy.searchTerm
    }
    setQsParams(searchBy)
  }

  return {
    data,
    totalCount,
    page: qsParams.pageNumber,
    term: qsParams.searchTerm,
    totalPages,
    isLoading,
    onSearch,
    onPageChange,
    qsParams,
    setQsParams
  }
}

export default useDataFetching
