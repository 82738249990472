import React from 'react'
import PurchaseOrdersGeneralList from '../../components/modules/location/replenishment/PurchaseOrdersGeneralList'

export const REPLENISHMENT_LIST = '/replenishment/general-view'

const ReplenishmentRoutes = [
  {
    path: REPLENISHMENT_LIST,
    pageTitle: 'Replenishment',
    icon: 'clipboard list',
    isMenu: true,
    menuMatchingRoutes: [REPLENISHMENT_LIST],
    exact: true,
    breadCrumbs: [
      {
        name: 'Replenishment'
      }
    ],
    component: props => <PurchaseOrdersGeneralList {...props} />
  }
]

export default ReplenishmentRoutes
