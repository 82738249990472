import React from 'react'
import InventoryZoneList from '../../components/modules/master/inventoryZones/list/InventoryZoneList'
import InventoryZoneAddEdit from '../../components/modules/master/inventoryZones/addEdit/InventoryZoneAddEdit'

export const INVENTORY_ZONES_LISTING_LINK = '/inventory-zones/list'
export const INVENTORY_ZONES_ADD_LINK = '/inventory-zones/add'
export const INVENTORY_ZONES_EDIT_LINK = '/inventory-zones/edit'

const InventoryZonesRoutes = [
  {
    path: INVENTORY_ZONES_LISTING_LINK,
    pageTitle: 'Inventory Zones',
    icon: 'clipboard list',
    isMenu: true,
    menuMatchingRoutes: [INVENTORY_ZONES_LISTING_LINK, INVENTORY_ZONES_ADD_LINK],
    exact: true,
    breadCrumbs: [
      {
        name: 'Inventory Zones'
      }
    ],
    component: (props) => {
      return (
        <InventoryZoneList {...props} />
      )
    }
  },
  {
    path: INVENTORY_ZONES_ADD_LINK,
    pageTitle: 'Add Inventory Zone',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Inventory Zones',
        path: INVENTORY_ZONES_LISTING_LINK
      },
      {
        name: 'Add'
      }
    ],
    exact: true,
    component: (props) => {
      return (
        <InventoryZoneAddEdit {...props} />
      )
    }
  },
  {
    path: INVENTORY_ZONES_EDIT_LINK + '/:id',
    pageTitle: 'Edit Inventory Zone',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Inventory Zones',
        path: INVENTORY_ZONES_LISTING_LINK
      },
      {
        name: 'Edit'
      }
    ],
    exact: true,
    component: (props) => {
      return (
        <InventoryZoneAddEdit {...props} />
      )
    }
  }
]

export default InventoryZonesRoutes
