import React from 'react'
import PropTypes from 'prop-types'

class CustomSimpleCellFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool])
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    return <div>{this.props.value}</div>
  }
}

export default CustomSimpleCellFormatter