import { useCallback, useState } from 'react'

/**
 * @typedef {import('./use-paginated-products-grid-selection').setPageSelections} setPageSelections}
 * @typedef {import('./use-paginated-products-grid-selection').getSelectedItemsByPage} getSelectedItemsByPage}
 * @typedef {import('./use-paginated-products-grid-selection').getAllItemsSelectedByProperty} getAllItemsSelectedByProperty}
 * @typedef {import('./use-paginated-products-grid-selection').resetGridSelection} resetGridSelection}
 */

/**
 * @return {[
 *  Array,
 *  setPageSelections,
 *  getSelectedItemsByPage,
 *  getAllItemsSelectedByProperty,
 *  resetGridSelection
 * ]}
 */
const usePaginatedGridSelection = () => {
  /** @type {[Array, Function]} */
  const [gridGlobalSelectionState, setGridGlobalSelectionState] = useState([])

  /**
   * @type {setPageSelections}
   */
  const setPageSelections = (page, selections, isSelectedAll = false) => {
    const pageSelections = []
    if (!isSelectedAll) {
      pageSelections[page] = {
        isSelectedAll,
        selectedRows: selections
      }
    } else {
      if (selections.length === 0) {
        pageSelections[page] = {
          isSelectedAll,
          selectedRows: []
        }
      } else if (selections.length === 1) {
        let currentPageSelections = []
        if (gridGlobalSelectionState[page]) {
          currentPageSelections = gridGlobalSelectionState[page].selectedRows
        }

        pageSelections[page] = {
          isSelectedAll,
          selectedRows: [...currentPageSelections, ...selections]
        }
      } else {
        pageSelections[page] = {
          isSelectedAll,
          selectedRows: [...selections]
        }
      }
    }
    const _gridGlobalSelectionState = gridGlobalSelectionState
    _gridGlobalSelectionState[page] = pageSelections[page]
    setGridGlobalSelectionState(_gridGlobalSelectionState)
  }

  /** @type {getSelectedItemsByPage} */
  const getSelectedItemsByPage = useCallback(
    page => {
      if (!gridGlobalSelectionState[page]) {
        return { isSelectedAll: false, selectedRows: [] }
      }

      return gridGlobalSelectionState[page]
    },
    [gridGlobalSelectionState]
  )

  /**
   * @type {getAllItemsSelectedByProperty}
   */
  const getAllItemsSelectedByProperty = propertyName => {
    const itemsSelectedByProperty = []

    gridGlobalSelectionState.map(page =>
      page.selectedRows.map(item =>
        itemsSelectedByProperty.push(item[propertyName])
      )
    )

    return itemsSelectedByProperty
  }

  /** @type {resetGridSelection}*/
  const resetGridSelection = useCallback(() => {
    setGridGlobalSelectionState([])
  }, [setGridGlobalSelectionState])

  return [
    gridGlobalSelectionState,
    setPageSelections,
    getSelectedItemsByPage,
    getAllItemsSelectedByProperty,
    resetGridSelection
  ]
}

export default usePaginatedGridSelection
