import _ from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import { withRouter } from 'react-router'
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Select
} from 'semantic-ui-react'
import {
  NotFoundException,
  withErrorHandler
} from '../../../../../hoc/withErrorHandler'
import { withModal } from '../../../../../hoc/withModal'
import { withRequest } from '../../../../../hoc/withRequest'
import InventoryZonesRepository from '../../../../../repositories/InventoryZonesRepository'
import LocationsRepository from '../../../../../repositories/LocationsRepository'
import LocationTypesRepository from '../../../../../repositories/LocationTypesRepository'
import PricingZoneRepository from '../../../../../repositories/PricingZoneRepository'
import ProductZoneRepository from '../../../../../repositories/ProductZoneRepository'
import {
  LOCATIONS_EDIT_LINK,
  LOCATIONS_LISTING_LINK
} from '../../../../../routes/masterLayoutRoutes/LocationsRoutes'
import { sleep } from '../../../../../utils'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { EditForm } from '../../../../common/form/edit-form'
import HeaderFormGroup from '../../../../common/headerFormGroup'
import Popup from '../../../../common/popup/Popup'
import './LocationsAddEdit.css'
import validate from './LocationValidator'

/**
 * @typedef {import('./location').LocationTypeState} LocationTypeState
 */

class LocationsAddEdit extends React.Component {
  /** @type {LocationTypeState} state*/
  state = {
    item: {
      name: '',
      locationName: '',
      description: '',
      businessName: '',
      addressLine1: '',
      locationTypeId: '',
      administrativeDistrictLevel1: '',
      locality: '',
      postalCode: '',
      country: 'US',
      timezone: '',
      languageCode: 'en-US',
      type: '',
      locationType: '',
      productZone: '',
      pricingZone: '',
      inventoryZone: '',
      warehouse: ''
    },
    locationsTypes: [],
    pricingZones: [],
    productZones: [],
    inventoryZones: [],
    nameNewZone: '',
    currentRepository: undefined,
    loading: false
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      _.isEqual(this.state, nextState) &&
      _.isEqual(this.props.requestState, nextProps.requestState)
    ) {
      return false
    }
    return true
  }

  async componentDidMount () {
    this.props.setLoading(true)
    const { id } = this.props.match.params
    await this.initForm()
    if (id) {
      try {
        const data = await LocationsRepository.get(id)
        if (_.isEmpty(data)) {
          throw new Error(NotFoundException)
        }

        const {
          locationName,
          businessName,
          isActive,
          administrativeDistrictLevel1,
          locality,
          timezone,
          type,
          addressLine1,
          description,
          postalCode,
          languageCode,
          country,
          locationType,
          inventoryZone,
          pricingZone,
          productZone,
          warehouse
        } = data
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            id,
            locationName,
            businessName,
            isActive,
            administrativeDistrictLevel1,
            locality,
            timezone,
            type,
            addressLine1,
            description,
            postalCode,
            languageCode,
            country,
            locationType,
            inventoryZone,
            pricingZone,
            productZone,
            warehouse
          }
        }))
        this.props.setSuccess({
          message: `"${this.state.item.locationName}" was saved successfully`
        })
      } catch (err) {
        console.log(err)
        this.props.handleError(err)
      }
    }
    this.props.setLoading(false)
  }

  initForm = async () => {
    const locationTypesRepository = LocationTypesRepository.filterBy({
      pageSize: 9999
    })
    const pricingZoneRepository = PricingZoneRepository.filterBy({
      pageSize: 9999
    })
    const productZoneRepository = ProductZoneRepository.filterBy({
      pageSize: 9999
    })
    const inventoryZonesRepository = InventoryZonesRepository.filterBy({
      pageSize: 9999
    })
    const zonesData = await Promise.all([
      locationTypesRepository,
      pricingZoneRepository,
      productZoneRepository,
      inventoryZonesRepository
    ])
    const locationsTypes = zonesData[0].items.map(item => {
      return this.mapZoneValuesByZoneName(item, 'locationZoneName')
    })
    const pricingZones = zonesData[1].items.map(item => {
      return this.mapZoneValuesByZoneName(item, 'pricingZoneName')
    })
    const productZones = zonesData[2].items.map(item => {
      return this.mapZoneValuesByZoneName(item, 'productZone')
    })
    const inventoryZones = zonesData[3].items.map(item => {
      return this.mapZoneValuesByZoneName(item, 'inventoryZoneName')
    })

    this.setState({
      locationsTypes,
      pricingZones,
      productZones,
      inventoryZones
    })
  }

  mapZoneValuesByZoneName = (item, zoneName) => {
    return {
      key: item.PK,
      value: item.PK,
      text: item[zoneName],
      description: item.description
    }
  }

  onSuccess = (uniqueId, formValues) => {
    this.props.setSuccess({
      message: `"${formValues.item.locationName}" was saved successfully`
    })
    this.props.history.push(`${LOCATIONS_EDIT_LINK}/${uniqueId}?add=true`)
  }

  onFailure = err => {
    this.props.setFailure(err)
  }

  validateValues = values => {
    return validate(values.item, {})
  }

  onSubmit = async (values, setSubmitting, setErrors) => {
    setSubmitting(true)
    try {
      const response = await LocationsRepository.save(values.item)
      await sleep()
      this.onSuccess(response.uniqueId, values)
    } catch (err) {
      this.onFailure(err.response.data)
      setSubmitting(false)
      return
    }
    setSubmitting(false)
  }

  setNewZone = async () => {
    this.setState({ loading: true, errorMessage: '' })
    try {
        await this.state.currentRepository.save({
        zoneName: this.state.nameNewZone,
        description: ''
      })
      await sleep(2000)
      await this.initForm()
      this.setState({
        showLocationPopup: false,
        nameNewZone: '',
        loading: false
      })
    } catch (err) {
      this.onFailure(err)
      this.setState({ loading: false, errorMessage: err.response.data.message })
      return
    }
  }

  renderPopupBody = () => {
    return (
      <>
        <Header>{this.state.currentZoneTitle}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                id='name'
                name='name'
                control={Input}
                placeholder='Name'
                value={this.state.nameNewZone}
                onChange={(event, txt) => {
                  this.setState({ nameNewZone: txt.value })
                }}
                style={{ width: '100%' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Column style={{ width: 'auto' }}>
            <span style={{color: 'red'}}>{this.state.errorMessage}</span>
          </Grid.Column>
          <Grid.Column floated='right' style={{ width: 'auto' }}>
            <Button
              primary
              loading={this.state.loading}
              disabled={!this.state.nameNewZone.length || this.state.loading}
              onClick={() => this.setNewZone()}
            >
              Save
            </Button>
          </Grid.Column>
        </Grid>
      </>
    )
  }

  locationZonesLabel = (title, type, currentRepository) => {
    return (
      <Grid divided='vertically'>
        <Grid.Row columns={2}>
          <Grid.Column>
            <b>{title.replace('Add', '')}</b>
          </Grid.Column>
          <Grid.Column style={{ textAlign: 'center' }}>
            <span
              className='addZoneLink'
              onClick={() =>
                this.setState({
                  currentZoneTitle: title,
                  showLocationPopup: !this.state.showLocationPopup,
                  currentPopup: type,
                  currentRepository
                })
              }
            >
              {title}
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  form = (
    { item },
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    touched
  ) => {
    const { add } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    })
    const { error, message } = this.props.requestState
    return (
      <>
        {add && message && (
          <Message positive header='Success' content={message} />
        )}
        {error ? <Message negative header='Error' content={message} /> : ''}
        <HeaderFormGroup title='Basic Information'>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  id='locationName'
                  name='item.locationName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  control={Input}
                  label='Name'
                  error={touched?.item?.locationName && errors.locationName}
                  placeholder='Name'
                  value={item.locationName}
                  readOnly={!!this.props.match.params.id}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  id='description'
                  name='item.description'
                  error={touched?.item?.description && errors.description}
                  control={Input}
                  label='Description'
                  value={item.description}
                  placeholder='Description'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field
                  id='businessName'
                  name='item.businessName'
                  error={touched?.item?.businessName && errors.businessName}
                  control={Input}
                  label='Business Name'
                  value={item.businessName}
                  placeholder='Business Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field
                  id='type'
                  name='item.type'
                  error={touched?.item?.type && errors.type}
                  control={Select}
                  label="Square's Location Type"
                  value={item.type}
                  placeholder="Square's Location Type"
                  options={[
                    { key: 'PHYSICAL', value: 'PHYSICAL', text: 'PHYSICAL' },
                    { key: 'MOBILE', value: 'MOBILE', text: 'MOBILE' }
                  ]}
                  onChange={(opt, el) => {
                    setFieldValue('item.type', el.value)
                  }}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </HeaderFormGroup>

        <HeaderFormGroup title='Address Information'>
          <Form.Field
            id='addressLine1'
            name='item.addressLine1'
            error={touched?.item?.addressLine1 && errors.addressLine1}
            control={Input}
            label='Address Line 1'
            value={item.addressLine1}
            placeholder='Address Line 1'
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Field
            id='administrativeDistrictLevel1'
            name='item.administrativeDistrictLevel1'
            error={
              touched?.item?.administrativeDistrictLevel1 &&
              errors.administrativeDistrictLevel1
            }
            control={Select}
            label='State'
            value={item.administrativeDistrictLevel1}
            placeholder='Administrative District Level 1'
            options={[
              { key: 'CA', value: 'CA', text: 'California' },
              { key: 'NY', value: 'NY', text: 'New York' }
            ]}
            onChange={(opt, el) => {
              setFieldValue('item.administrativeDistrictLevel1', el.value)
            }}
            onBlur={handleBlur}
          />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  id='locality'
                  name='item.locality'
                  error={touched?.item?.locality && errors.locality}
                  control={Input}
                  label='City'
                  value={item.locality}
                  placeholder='City'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  id='postalCode'
                  name='item.postalCode'
                  error={touched?.item?.postalCode && errors.postalCode}
                  control={Input}
                  label='Zip Code'
                  value={item.postalCode}
                  placeholder='Zip Code'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field
                  id='country'
                  name='item.country'
                  error={touched?.item?.country && errors.country}
                  control={Select}
                  value={item.country}
                  placeholder='Country'
                  options={[{ key: 'US', value: 'US', text: 'United States' }]}
                  onChange={(opt, el) => {
                    setFieldValue('item.country', el.value)
                  }}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </HeaderFormGroup>

        <HeaderFormGroup title='Warehouse'>
          <Form.Field
            id='warehouse'
            name='item.warehouse'
            error={touched?.item?.warehouse && errors.warehouse}
            control={Select}
            value={item.warehouse}
            placeholder='Warehouse'
            options={[
              {
                key: 'America/New_York-Warehouse',
                value: 'America/New_York-Warehouse',
                text: 'America/New York-Warehouse'
              },
              {
                key: 'America/Los_Angeles-Warehouse',
                value: 'America/Los_Angeles-Warehouse',
                text: 'America/Los Angeles-Warehouse'
              }
            ]}
            onChange={(opt, el) => {
              setFieldValue('item.warehouse', el.value)
            }}
            onBlur={handleBlur}
          />
        </HeaderFormGroup>

        <HeaderFormGroup title='Preferred Language'>
          <Form.Field
            id='languageCode'
            name='item.languageCode'
            error={touched?.item?.languageCode && errors.languageCode}
            control={Select}
            value={item.languageCode}
            placeholder='Language'
            options={[{ key: 'en-us', value: 'en-US', text: 'English' }]}
            onChange={(opt, el) => {
              setFieldValue('item.languageCode', el.value)
            }}
            onBlur={handleBlur}
          />
        </HeaderFormGroup>

        <HeaderFormGroup title='Business Hours'>
          <Form.Field
            id='timezone'
            name='item.timezone'
            error={touched?.item?.timezone && errors.timezone}
            control={Select}
            label='Timezone'
            value={item.timezone}
            placeholder='Timezone'
            options={[
              {
                key: 'America/New_York',
                value: 'America/New_York',
                text: 'America/New York'
              },
              {
                key: 'America/Los_Angeles',
                value: 'America/Los_Angeles',
                text: 'America/Los Angeles'
              }
            ]}
            onChange={(opt, el) => {
              setFieldValue('item.timezone', el.value)
            }}
            onBlur={handleBlur}
          />
        </HeaderFormGroup>

        <HeaderFormGroup title="Location's Zones">
          <Popup
            open={this.state.showLocationPopup}
            onClose={() =>
              this.setState({ showLocationPopup: false, nameNewZone: '', errorMessage: '' })
            }
            body={this.renderPopupBody()}
          />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  id='locationType'
                  name='item.locationType'
                  error={touched?.item?.locationType && errors.locationType}
                  control={Select}
                  label={this.locationZonesLabel(
                    'Add Location Type',
                    'locationType',
                    LocationTypesRepository
                  )}
                  options={this.state.locationsTypes.map(
                    ({ key, value, text }) => ({ key, value, text })
                  )}
                  placeholder='Location Type'
                  onChange={(opt, el) => {
                    setFieldValue('item.locationType', el.value)
                  }}
                  value={item.locationType}
                  onBlur={handleBlur}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  id='pricingZone'
                  name='item.pricingZone'
                  error={touched?.item?.pricingZone && errors.pricingZone}
                  control={Select}
                  label={this.locationZonesLabel(
                    'Add Pricing Zone',
                    'pricingZone',
                    PricingZoneRepository
                  )}
                  options={this.state.pricingZones.map(
                    ({ key, value, text }) => ({ key, value, text })
                  )}
                  placeholder='Pricing Zone'
                  onChange={(opt, el) => {
                    setFieldValue('item.pricingZone', el.value)
                  }}
                  value={item.pricingZone}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  id='productZone'
                  name='item.productZone'
                  error={touched?.item?.productZone && errors.productZone}
                  control={Select}
                  label={this.locationZonesLabel(
                    'Add Product Zone',
                    'productZone',
                    ProductZoneRepository
                  )}
                  options={this.state.productZones.map(
                    ({ key, value, text }) => ({ key, value, text })
                  )}
                  placeholder='Product Zone'
                  onChange={(opt, el) => {
                    setFieldValue('item.productZone', el.value)
                  }}
                  onBlur={handleBlur}
                  value={item.productZone}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  id='inventoryZone'
                  name='item.inventoryZone'
                  error={touched?.item?.inventoryZone && errors.inventoryZone}
                  control={Select}
                  label={this.locationZonesLabel(
                    'Add Inventory Zone',
                    'inventoryZone',
                    InventoryZonesRepository
                  )}
                  options={this.state.inventoryZones.map(
                    ({ key, value, text }) => ({ key, value, text })
                  )}
                  placeholder='Inventory Zone'
                  onChange={(opt, el) => {
                    setFieldValue('item.inventoryZone', el.value)
                  }}
                  onBlur={handleBlur}
                  value={item.inventoryZone}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </HeaderFormGroup>
      </>
    )
  }

  render () {
    return (
      <>
        {this.props.renderModal()}
        <EditForm
          loading={this.props.requestState.loading}
          initialValues={{ item: this.state.item }}
          validate={this.validateValues}
          onSubmit={this.onSubmit}
          header={<h3>{this.props.route.pageTitle}</h3>}
          breadCrumb={<BreadCrumb route={this.props.route} />}
          form={this.form}
          cancel={LOCATIONS_LISTING_LINK}
        />
      </>
    )
  }
}

LocationsAddEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  route: PropTypes.shape({
    pageTitle: PropTypes.string
  }),
  requestState: PropTypes.shape({
    error: PropTypes.any,
    message: PropTypes.string,
    loading: PropTypes.bool
  }),
  setLoading: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setFailure: PropTypes.func.isRequired,
  renderModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired
}

export default withRouter(
  withRequest(withModal(withErrorHandler(LocationsAddEdit)))
)
