export const sleep = (ms = 1000) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
String.prototype.ucfirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
