import AmplifyHttpRequest from '../AmplifyHttpRequest'

const requestHandler = AmplifyHttpRequest.createInstance('transactions')

export const get = (endpoint, configs = {}) => {
  return requestHandler.get(endpoint, configs)
}

export const post = (endpoint, configs = {}) => {
  return requestHandler.post(endpoint, configs, { body: configs })
}

export default { get, post }
