import PropTypes from 'prop-types'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import useDataFetching from '../../../../../hooks/useDataFetching'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  PRODUCTS_ADD_LINK,
  PRODUCTS_VIEW_LINK
} from '../../../../../routes/masterLayoutRoutes/ProductsRoutes'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ItemList } from '../../../../common/list'
import CustomSimpleCellFormatter from '../../../../common/list/CustomSimpleCellFormatter'
import { IN_LOCATION_PRODUCT_EDIT_LINK } from '../../../../../routes/locationLayoutRoutes'

const ProductList = props => {
  const history = useHistory()
  const {
    data: products,
    totalCount,
    page,
    term,
    totalPages,
    isLoading,
    onSearch,
    onPageChange
  } = useDataFetching(ProductsRepository)

  const rows = products.map(({ productName, brand = '', PK = '000000' }) => {
    const viewUrl = `${PRODUCTS_VIEW_LINK}/${PK}`
    const viewBtn = (
      <Button
        primary
        as={Link}
        to={viewUrl}
        icon='pencil'
        size='mini'
        content='View'
      />
    )
    return { productTitle: productName, brand, PK, viewUrl, viewBtn }
  })

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={[
        { key: 'PK', name: 'Product #', width: 100 },
        { key: 'productTitle', name: 'Product Title', width: 350 },
        { key: 'brand', name: 'Brand' },
        {
          key: 'viewBtn',
          width: 110,
          formatter: CustomSimpleCellFormatter
        }
      ]}
      isLoading={isLoading}
      rows={rows}
      rowCount={rows.length}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      searchTerm={term}
      totalPages={totalPages}
      onSortChanged={(sortColum, sortDirection) => {
        return rows
      }}
      onSearch={onSearch}
      showEdit
      onRowDoubleClick={index => {
        history.push(`${PRODUCTS_VIEW_LINK}/${rows[index].PK}`)
      }}
      addLink={PRODUCTS_ADD_LINK}
    />
  )
}

ProductList.propTypes = {
  route: PropTypes.any
}

export default ProductList
