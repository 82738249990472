import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Input } from 'semantic-ui-react'

const SearchInput = ({
  onSearch,
  searchBy = 'searchTerm',
  onSearchCallback,
  id = 'searchInput'
}) => {
  const [term, setTerm] = useState('')

  const trimmedTerm = term.trim()
  const triggerSearch = () => {
    if (trimmedTerm.length >= 3) {
      if (onSearchCallback) onSearchCallback(trimmedTerm)
      onSearch(searchBy, trimmedTerm)
    }
  }

  return (
    <Input
      fluid
      id={id}
      type='text'
      placeholder='Search...'
      value={term}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          triggerSearch()
        }
      }}
      onChange={e => {
        const value = e.target.value
        setTerm(value)
        if (!value) {
          onSearch(null, null)
        }
      }}
      action={{
        labelPosition: 'right',
        icon: 'search',
        content: 'Search',
        id: 'search_btn',
        type: 'reset',
        disabled: term.trim().length < 3,
        onClick: e => {
          e.preventDefault()
          triggerSearch()
        }
      }}
    />
  )
}

SearchInput.propTypes = {
  onSearch: PropTypes.func
}

export default SearchInput
