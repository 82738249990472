import _ from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import { withRouter } from 'react-router'
import { Form, Grid, Input, Message } from 'semantic-ui-react'
import validator from 'validator'
import {
  NotFoundException,
  withErrorHandler
} from '../../../../../hoc/withErrorHandler'
import { withModal } from '../../../../../hoc/withModal'
import { withRequest } from '../../../../../hoc/withRequest'
import InventoryZonesRepository from '../../../../../repositories/InventoryZonesRepository'
import ProductsRepository from '../../../../../repositories/ProductsRepository'
import {
  ENTITY_PREFIX_INVENTORYZONE,
  ENTITY_PREFIX_PRODUCT
} from '../../../../../repositories/variablesRepository'
import {
  INVENTORY_ZONES_ADD_LINK,
  INVENTORY_ZONES_EDIT_LINK,
  INVENTORY_ZONES_LISTING_LINK
} from '../../../../../routes/masterLayoutRoutes/InventoryZonesRoutes'
import { sleep } from '../../../../../utils'
import AssignedItems from '../../../../common/assignedItems/AssignedItems'
import { BreadCrumb } from '../../../../common/breadCrumb'
import { ERROR_BLANK } from '../../../../common/errors'
import { EditForm } from '../../../../common/form/edit-form/EditForm'
import Editor from './editor'

/**
 * @typedef {import('./inventory-zone').InventoryZone} InventoryZone
 */
class InventoryZoneAddEdit extends React.Component {
  /** @type {InventoryZone}*/
  state = {
    id: undefined,
    zoneName: '',
    description: ''
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      _.isEqual(this.state, nextState) &&
      _.isEqual(this.props.requestState, nextProps.requestState)
    ) {
      const { id } = this.props.match.params
      if (id) {
        return true
      }
      return false
    }
    return true
  }

  async componentDidMount () {
    const { id } = this.props.match.params
    if (id) {
      try {
        this.props.setLoading(true)
        const response = await InventoryZonesRepository.get(id)

        if (_.isEmpty(response)) {
          throw new Error(NotFoundException)
        }

        const { description, inventoryZoneName: zoneName } = response.Item

        this.setState({
          id,
          zoneName,
          description
        })
        this.props.setSuccess({
          message: `"${zoneName}" was saved successfully`
        })
      } catch (err) {
        this.props.handleError(err)
      }
      this.props.setLoading(false)
    }
  }

  onSuccess = (uniqueId, formValues) => {
    this.props.setSuccess({
      message: `"${formValues.zoneName}" was saved successfully`
    })
    this.props.history.push(`${INVENTORY_ZONES_EDIT_LINK}/${uniqueId}?add=true`)
  }

  onFailure = err => {
    this.props.setFailure(err)
  }

  validateValues = values => {
    const errors = {}
    if (validator.isEmpty(values.zoneName)) {
      errors.zoneName = ERROR_BLANK
    }
    if (validator.isEmpty(values.description)) {
      errors.description = ERROR_BLANK
    }

    return errors
  }

  onSubmit = async (values, setSubmitting, setErrors) => {
    const { zoneName, description } = values
    setSubmitting(true)
    let response
    try {
      response = await InventoryZonesRepository.save({
        zoneName,
        description,
        id: this.state.id
      })
      await sleep()
    } catch (err) {
      this.onFailure(err.response.data)
      setSubmitting(false)
      return
    }

    setSubmitting(false)
    this.onSuccess(response.uniqueId, values)
  }

  form = (
    { zoneName, description },
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    touched
  ) => {
    const { add } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    })
    const { error, message } = this.props.requestState
    return (
      <>
        {add && message ? (
          <Message positive header='Success' content={message} />
        ) : (
          ''
        )}
        {error ? <Message negative header='Error' content={message} /> : ''}
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                id='zoneName'
                name='zoneName'
                onChange={handleChange}
                onBlur={handleBlur}
                control={Input}
                label='Name'
                error={touched.zoneName && errors.zoneName}
                placeholder='Name'
                value={zoneName}
                readOnly={!!this.props.match.params.id}
              />
            </Grid.Column>

            <Grid.Column>
              <Form.Field
                id='description'
                name='description'
                onChange={handleChange}
                onBlur={handleBlur}
                control={Input}
                label='Description'
                error={touched.description && errors.description}
                placeholder='Description'
                value={description}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }

  handleChangeField = async (value, row, target) => {
    const { id: zoneId } = this.props.match.params
    const errors = []
    if (validator.isEmpty(value) || validator.isEmpty(row.productNumber)) {
      errors.push({ message: 'Incorrect Input' })
    }

    if (!validator.isNumeric(value)) {
      errors.push({ message: 'The value should be numeric' })
    }

    if (errors.length > 0) {
      return errors
    }

    this.props.setLoading(true)
    let newRow = row
    newRow[target] = value
    try {
      await ProductsRepository.saveProductPropertyByZone(
        ENTITY_PREFIX_PRODUCT + row.productNumber,
        ENTITY_PREFIX_INVENTORYZONE + zoneId,
        newRow
      )
    } catch (e) {
      console.log(e)
    }
    this.props.setLoading(false)
  }

  render () {
    const { zoneName, description } = this.state
    return (
      <>
        {this.props.renderModal()}
        <EditForm
          loading={this.props.requestState.loading}
          initialValues={{ zoneName, description }}
          validate={this.validateValues}
          onSubmit={this.onSubmit}
          header={<h3>{this.props.route.pageTitle}</h3>}
          breadCrumb={<BreadCrumb route={this.props.route} />}
          form={this.form}
          cancel={INVENTORY_ZONES_LISTING_LINK}
          assignedItems={
            <AssignedItems
              itemId={this.state.id}
              prefixZone={ENTITY_PREFIX_INVENTORYZONE}
              zoneRepository={InventoryZonesRepository}
              addLink={INVENTORY_ZONES_ADD_LINK}
              columnsDefinition={[
                {
                  key: 'productNumber',
                  name: 'Product #',
                  width: 100,
                  resizable: true,
                  defaultValue: '',
                  serverResponseKey: 'PK'
                },
                {
                  key: 'productName',
                  name: 'Name',
                  resizable: true,
                  defaultValue: 'Not set',
                  serverResponseKey: 'productName'
                },
                {
                  key: 'brand',
                  name: 'Brand',
                  width: 200,
                  resizable: true,
                  serverResponseKey: 'brand'
                },
                {
                  key: 'minimumQuantity',
                  name: 'Minimum Quantity',
                  width: 200,
                  resizable: true,
                  serverResponseKey: 'minimumQuantity',
                  editor: <Editor target='minimumQuantity' />,
                  handleChange: (value, row) => {
                    if (value) this.handleChangeField(value, row, 'minimumQuantity')
                  }
                },
                {
                  key: 'reorderDays',
                  name: 'Reorder Days',
                  width: 200,
                  resizable: true,
                  editor: <Editor target='reorderDays' />,
                  serverResponseKey: 'reorderDays',
                  handleChange: (value, row) => {
                    if (value) this.handleChangeField(value, row, 'reorderDays')
                  }
                },
                {
                  key: 'targetDays',
                  name: 'Target Days',
                  width: 200,
                  resizable: true,
                  editor: <Editor target='targetDays' />,
                  serverResponseKey: 'targetDays',
                  handleChange: (value, row) => {
                    if (value) this.handleChangeField(value, row, 'targetDays')
                  }
                }
              ]}
            />
          }
        />
      </>
    )
  }
}

InventoryZoneAddEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  route: PropTypes.shape({
    pageTitle: PropTypes.string
  }),
  requestState: PropTypes.shape({
    error: PropTypes.any,
    message: PropTypes.string,
    loading: PropTypes.bool
  }),
  setLoading: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setFailure: PropTypes.func.isRequired,
  renderModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired
}

export default withRouter(
  withRequest(withModal(withErrorHandler(InventoryZoneAddEdit)))
)
