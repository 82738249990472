import RequestHandler, { stringifyQueryParams } from './HttpRequestsHandler'
const basePath = '/pricing-zone'

const filterBy = params => {
  return RequestHandler.get(`${basePath}${stringifyQueryParams(params)}`)
}
const getAll = () => {
  return RequestHandler.get(basePath)
}

const get = id => {
  return RequestHandler.get(`${basePath}/${id}`)
}

const save = pricingZone => {
  return RequestHandler.post(basePath, pricingZone)
}

const getProducts = (url, id) => {
  return RequestHandler.get(`${basePath}/${url}/${id}`)
}

const PricingZoneRepository = {
  filterBy,
  get,
  getAll,
  save,
  getProducts
}

export default PricingZoneRepository
