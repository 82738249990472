import AmplifyHttpRequest from './AmplifyHttpRequest'

const requestHandler = AmplifyHttpRequest.createInstance('mcs')

export const stringifyQueryParams = params => {
  let parameters = '?'

  for (const param in params) {
    if (Object.prototype.hasOwnProperty.call(params, param)) {
      parameters += `${param}=${encodeURIComponent(params[param])}&`
    }
  }

  return parameters.substr(0, parameters.length - 1)
}

export const get = async (endpoint, configs = {}) => {
  return requestHandler.get(endpoint, configs)
}

export const post = async (endpoint, payload = {}, configs = {}) => {
  return requestHandler.post(endpoint, payload, configs)
}

export default { get, post }
