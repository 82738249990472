import React from 'react'
import PropTypes from 'prop-types'
import { Card, Header, Segment } from 'semantic-ui-react'
import LocationCard from './LocationCard'

export const LocationTypeContainer = ({ locationTypeName, locations }) => {
  return (
    <div style={{ marginTop: '25px' }}>
      <Header textAlign='center' attached='top'>{locationTypeName}</Header>
      <Segment attached>
        <Card.Group doubling itemsPerRow={8}>
          {(
            locations &&
            locations.length > 0 &&
            locations.map((location, index) => {
              const { locationName, PK } = location
              return (
                <LocationCard
                  key={index}
                  header={locationName}
                  image=''
                  locationId={PK}
                  meta={locationName}
                />
              )
            })
          )}
        </Card.Group>
      </Segment>
    </div>
  )
}

LocationTypeContainer.propTypes = {
  locations: PropTypes.array,
  locationTypeName: PropTypes.string
}

export default LocationTypeContainer
