import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import ReactDataGrid from 'react-data-grid'
import { Link, useHistory, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonContent,
  ButtonGroup,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Input,
  Menu,
  Message,
  Segment
} from 'semantic-ui-react'
import PurchaseOrderContext from '../../../../context/PurchaseOrderContext'
import LocationsRepository from '../../../../repositories/LocationsRepository'
import ProductsRepository from '../../../../repositories/ProductsRepository'
import PurchaseOrdersVendorRepository from '../../../../repositories/transactionsApi/PurchaseOrdersVendorRepository'
import { ENTITY_PREFIX_LOCATION } from '../../../../repositories/variablesRepository'
import {
  IN_LOCATION_VENDOR_PO_LINK
} from '../../../../routes/locationLayoutRoutes/VendorPORoutes'
import { BreadCrumb } from '../../../common/breadCrumb/BreadCrumb'
import EmptyRowsView from '../../../common/emptyRowsView/EmptyRowsView'
import SearchInput from '../../../common/search/SearchInput'
import POAddProductModal from './POAddProductModal'
import WarehousePOShipments from './WarehousePOShipments'
import { ACTIONS, STATUSES } from './constants'

const VendorPOAddEdit = props => {
  const history = useHistory()

  const [readOnlyValues, setReadOnlyValues] = useState({
    date: moment().format('m/d/Y'),
    status: 'New',
    type: 'Whs Manual'
  })

  const [rows, setRows] = useState([])
  const [selectedIndexes, setSelectedIndexes] = useState([])
  const [message, setMessage] = useState()
  const [topErrors, setTopErrors] = useState([])
  const [topWarnings, setTopWarnings] = useState([])
  const [id, setId] = useState(0)
  const [locationName, setLocationName] = useState()
  const [confirmOpen, setConfirmOpen] = useState()
  const [confirmModalHeader, setConfirmModalHeader] = useState()
  const [confirmModalContent, setConfirmModalContent] = useState(<></>)
  const [purchaseOrder, setPurchaseOrder] = useState({
    shipments: []
  })
  const [storablePurchaseOrder, setStorablePurchaseOrder] = useState({
    shipments: []
  })
  const [action, setAction] = useState(props.match.params.action)
  const [isLoading, setIsLoading] = useState()
  const [productLoaded, setProductLoaded] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const gridModel = [
    {
      key: 'productNumber',
      name: 'SKU',
      resizable: true,
      editable: false,
      width: 150,
      serverResponseKey: 'sku'
    },
    {
      key: 'name',
      name: 'Name',
      resizable: true,
      editable: false,
      serverResponseKey: 'name'
    },
    {
      key: 'reqQty',
      name: 'Req. Qty.',
      width: 75,
      editable:
        (action !== ACTIONS.edit && action !== ACTIONS.view) ?? false,
      defaultValue: 1,
      resizable: true
    }
  ]

  const memoizedFetchPOData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await PurchaseOrdersVendorRepository.get(id)

      if (_.isEmpty(data)) {
        setTopErrors([{ message: 'Purchase Order Not Found' }])
      } else {
        const {
          id,
          status,
          type,
          created,
          locationName,
          products,
          shipments
        } = data
        setId(id)
        setLocationName(locationName)
        setReadOnlyValues({
          status,
          type,
          date: moment(created).format('M/d/Y')
        })

        data.shipments = await getShipmentsProductsData(shipments)
        data.products = await getProductsData(products)
        setPurchaseOrder(data)
        loadRows(data.products)
      }
      setIsLoading(false)
    } catch (err) {
      setTopErrors([err])
      setIsLoading(false)
    }
  }, [id])

  const hasTopErrors = useCallback(() => {
    return topErrors.length > 0
  }, [topErrors])

  const hasTopWarnings = useCallback(() => {
    return topWarnings.length > 0
  }, [topWarnings])

  const memoizedShouldFetchData = useCallback(() => {
    if (id && action !== ACTIONS.add) {
      if (!hasTopErrors() && !hasTopWarnings()) {
        return true
      }
    }

    return false
  }, [id, action, hasTopWarnings, hasTopErrors])

  useEffect(() => {
    if (memoizedShouldFetchData()) {
      memoizedFetchPOData()
    }

    ;(async function () {
      const { locationName } = await LocationsRepository.get(
        props.match.params.locationId
      )

      setLocationName(locationName)
    })()
  }, [
    memoizedFetchPOData,
    memoizedShouldFetchData,
    props.match.params.locationId,
    action
  ])

  const getShipmentsProductsData = async shipments => {
    const shipments_ = []
    for (let i = 0; i < shipments.length; i++) {
      const shipmentId = shipments[i].id
      shipments_[shipmentId] = shipments[i]
      const shipmentProducts = shipments_[shipmentId].products
      for (let j = 0; j < shipmentProducts.length; j++) {
        const { sku } = shipmentProducts[j]
        const { productName: name } = await ProductsRepository.get(sku)
        shipmentProducts[j].name = name
        shipmentProducts[j].qtyReceived = shipmentProducts[j].quantityReceived
      }
    }
    return shipments_
  }

  const getProductsData = async products => {
    for (let i = 0; i < products.length; i++) {
      const { sku } = products[i]
      const { productName: name } = await ProductsRepository.get(sku)
      products[i].name = name
    }

    return products
  }

  const save = async e => {
    const createPurchaseOrder = async storablePurchaseOrder => {
      try {
        storablePurchaseOrder.vendor = 'direct'
        const { data, errors: warnings } = await PurchaseOrdersVendorRepository.save(
          storablePurchaseOrder
        )
        if (warnings) {
          setAction(ACTIONS.view)
          setReadOnlyValues({
            date: moment().format('m/d/Y'),
            status: data.status,
            type: data.vendor
          })
          setTopWarnings(warnings)
        }

        if (warnings.length === 0) {
          history.push(
            IN_LOCATION_VENDOR_PO_LINK.replace(
              ':locationId',
              props.match.params.locationId
            ),
            {
              purchaseOrderNotification: {
                success: true,
                message: 'Purchase Order Created Successfully'
              }
            }
          )
        }
      } catch (err) {
        throw new Error(err)
      }
    }

    const receivePurchaseOrder = storablePurchaseOrder => {
      const savingPayload = {}
      const shipments = []

      for (const shipment of storablePurchaseOrder.shipments) {
        if (!shipment) {
          continue
        }
        for (const [index, product] of shipment.products.entries()) {
          const { sku, qtyReceived } = product
          shipment.products[index] = { sku, qtyReceived }
        }
        shipments.push(shipment)
      }

      savingPayload.shipments = shipments
      savingPayload.id = storablePurchaseOrder.id
      PurchaseOrdersVendorRepository.save(savingPayload)
    }

    switch (storablePurchaseOrder.status) {
      case STATUSES.draft:
      case STATUSES.processing:
        createPurchaseOrder(storablePurchaseOrder)
        break
      case STATUSES.open:
        receivePurchaseOrder(storablePurchaseOrder)
        break
      default:
    }
  }

  const onSearchProduct = async (field, term) => {
    const found = rows.filter(row => row.productNumber === term).length > 0

    if (!term || found) {
      return
    }

    setIsLoading(true)
    try {
      const listParams = {
        productNumber: term,
        zoneId: ENTITY_PREFIX_LOCATION + props.match.params.locationId
      }
      const { count, items } = await ProductsRepository.filterBy(listParams)
      if (count === 0) {
        throw new Error('Product not Found')
      }

      setProductLoaded({
        productNumber: items[0].PK,
        name: items[0].productName
      })
      setIsModalOpen(true)
    } catch (err) {
      console.log(err.message)
      setMessage(err.message)
    }

    setIsLoading(false)
  }

  const onRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (updated.reqQty < 1) {
      updated.reqQty = 1
    }

    const _rows = rows.slice()
    for (let i = fromRow; i <= toRow; i++) {
      _rows[i] = { ...rows[i], ...updated }
    }
    setRows(_rows)
  }

  const replaceRouteParams = () => {
    const { locationId, poId } = props.match.params
    for (const [index] of props.route.breadCrumbs.entries()) {
      if (props.route.breadCrumbs[index].path) {
        props.route.breadCrumbs[index].path = props.route.breadCrumbs[
          index
        ].path
          .replace(':locationId', locationId)
          .replace(':poId', poId)
      }
    }
    return props.route
  }

  const removeGridRows = () => {
    const _rows = rows.filter((row, idx) => !selectedIndexes.includes(idx))
    setRows(_rows)
    setSelectedIndexes([])
  }

  const addProduct = modalState => {
    setRows([
      ...rows,
      {
        productNumber: modalState.product.productNumber,
        name: modalState.product.name,
        reqQty: modalState.productReqQty,
        actions: ''
      }
    ])
  }

  const loadRows = products => {
    const _rows = products.map(({ sku, quantity: reqQty, name = '' }) => ({
      productNumber: sku,
      reqQty,
      name
    }))
    setRows(_rows)
  }

  const gridButtons = action => {
    if (action !== ACTIONS.edit && action !== ACTIONS.view) {
      return (
        <ButtonContent>
          <Button
            primary
            content='Remove'
            disabled={rows.length <= 0}
            onClick={e => {
              e.preventDefault()
              removeGridRows()
            }}
          />
        </ButtonContent>
      )
    }
    return <></>
  }

  const renderTopTextFields = () => {
    return (
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column>
            <Form.Field
              id='date'
              name='date'
              control={Input}
              label='Date'
              placeholder='date'
              value={readOnlyValues.date}
              readOnly
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Field
              id='status'
              name='status'
              control={Input}
              label='Status'
              placeholder='Status'
              value={readOnlyValues.status}
              readOnly
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Field
              id='type'
              name='type'
              control={Input}
              label='Type'
              placeholder='Type'
              value={readOnlyValues.type}
              readOnly
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  const renderSearchBar = action => {
    if (action !== ACTIONS.edit && action !== ACTIONS.view) {
      return (
        <Segment secondary>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <SearchInput
                  onSearch={(field, value) => {
                    onSearchProduct(field, value)
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )
    }
    return <></>
  }

  const hasProducts = () => {
    return rows.length > 0
  }

  function bottomButtons (dependencies) {
    const {
      setStorablePurchaseOrder,
      setConfirmOpen,
      setConfirmModalHeader,
      setConfirmModalContent,
      rows
    } = dependencies
    return {
      handler: _purchaseOrder => {
        setStorablePurchaseOrder(_purchaseOrder)
        setConfirmOpen(true)
      },
      element ({ id, locationName }, action) {
        return (
          <>
            <Button.Group>
              <Button
                as={Link}
                to={IN_LOCATION_VENDOR_PO_LINK.replace(
                  ':locationId',
                  props.match.params.locationId
                )}
                labelPosition='left'
                icon='arrow left'
                content='Back'
              />
            </Button.Group>
            <Button.Group color='blue' floated='right'>
              {action !== ACTIONS.edit && action !== ACTIONS.view ? (
                <Menu>
                  <Dropdown
                    button
                    text='Save as'
                    options={[
                      {
                        key: STATUSES.draft,
                        text: STATUSES.draft,
                        value: STATUSES.draft,
                        onClick: e => {
                          const storablePurchaseOrder = {
                            id,
                            locationName,
                            status: STATUSES.draft
                          }
                          storablePurchaseOrder.products = rows.map(row => {
                            return {
                              sku: row.productNumber,
                              quantity: parseInt(row.reqQty)
                            }
                          })
                          setConfirmModalHeader('Save as draft')
                          setConfirmModalContent(
                            'Are you sure you want to save this order as draft?'
                          )
                          this.handler(storablePurchaseOrder)
                        }
                      },
                      {
                        key: 'PO',
                        text: 'PO',
                        value: STATUSES.processing,
                        onClick: e => {
                          const storablePurchaseOrder = {
                            id,
                            locationName,
                            status: STATUSES.processing
                          }
                          storablePurchaseOrder.products = rows.map(row => {
                            return {
                              sku: row.productNumber,
                              quantity: parseInt(row.reqQty)
                            }
                          })
                          setConfirmModalHeader(
                            'Warning - Please read before you proceed'
                          )
                          setConfirmModalContent(
                            'This option saves the order and cannot be modified'
                          )
                          this.handler(storablePurchaseOrder)
                        }
                      }
                    ]}
                    simple
                    item
                    disabled={!hasProducts()}
                  />
                </Menu>
              ) : action === ACTIONS.edit ? (
                <Button
                  content='Save'
                  onClick={e => {
                    setConfirmModalHeader(
                      'Warning - please read before proceed'
                    )
                    setConfirmModalContent(
                      'All shipments marked as received will be updating the inventory'
                    )
                    this.handler(storablePurchaseOrder)
                  }}
                />
              ) : (
                <></>
              )}
            </Button.Group>
          </>
        )
      }
    }
  }

  const renderShipments = action => {
    return action === ACTIONS.edit ? <WarehousePOShipments /> : <></>
  }

  const renderErrors = () => {
    const errors = topErrors.map((error, index) => {
      let message
      if (error.sku) {
        message = `${error.sku} - ${error.description}`
      } else {
        message = error.message
      }
      return <Message.Item key={index}>{message}</Message.Item>
    })
    return topErrors.length > 0 ? (
      <Grid.Row>
        <Grid.Column>
          <Message negative style={{ border: 'solid #9F3A38 1px' }}>
            <Message.Header>Errors:</Message.Header>
            <Message.List>{errors}</Message.List>
          </Message>
        </Grid.Column>
      </Grid.Row>
    ) : (
      <></>
    )
  }

  const renderWarnings = () => {
    const warnings = topWarnings.map((warning, index) => {
      if (warning.sku) {
        return (
          <Message.Item key={index}>
            {`${warning.sku} - ${warning.description}`}
          </Message.Item>
        )
      }

      return null
    })
    return topWarnings.length > 0 ? (
      <Grid.Row>
        <Grid.Column>
          <Message color='yellow' style={{ border: 'solid #B58105 1px' }}>
            <Message.Header>Warnings:</Message.Header>
            <Message.List>{warnings}</Message.List>
          </Message>
        </Grid.Column>
      </Grid.Row>
    ) : (
      <></>
    )
  }

  return (
    <>
      <BreadCrumb route={replaceRouteParams()} />
      <PurchaseOrderContext.Provider
        value={{
          purchaseOrder,
          storablePurchaseOrder,
          setStorablePurchaseOrder
        }}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
          loading={isLoading}
        >
          <Segment.Group>
            <Segment align='right'>
              <ButtonGroup>
                <Button content='View Log' />
              </ButtonGroup>
            </Segment>
            <Segment loading={isLoading}>
              <Grid columns='equal'>
                {renderErrors()}
                {renderWarnings()}
                <Grid.Row>
                  <Grid.Column width={14}>{renderTopTextFields()}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>{renderSearchBar(action)}</Grid.Column>
                </Grid.Row>
                {message && (
                  <Grid.Row>
                    <Grid.Column>
                      <Message negative>{message}</Message>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Grid.Column align='right'>{gridButtons(action)}</Grid.Column>
                </Grid.Row>
              </Grid>
              <Segment style={{ padding: 0 }} className='listing-grid'>
                <ReactDataGrid
                  columns={gridModel}
                  rowGetter={i => rows[i]}
                  rowsCount={rows.length}
                  emptyRowsView={EmptyRowsView}
                  onGridRowsUpdated={onRowsUpdated}
                  maxHeight={100}
                  enableCellSelect
                  rowSelection={{
                    showCheckbox:
                      action !== ACTIONS.edit &&
                      action !== ACTIONS.view,
                    enableShiftSelect: true,
                    selectBy: {
                      indexes: selectedIndexes
                    },
                    onRowsSelected: rows => {
                      rows.forEach(it => {
                        selectedIndexes.push(it.rowIdx)
                      })
                      setSelectedIndexes([...selectedIndexes])
                    },
                    onRowsDeselected: rows => {
                      let indexes = selectedIndexes
                      if (rows.length > 1) {
                        indexes = []
                      } else {
                        const idx = selectedIndexes.findIndex(
                          element => element === rows[0].rowIdx
                        )
                        indexes.splice(idx, 1)
                      }
                      setSelectedIndexes(indexes)
                    }
                  }}
                />
              </Segment>
              {renderShipments(action)}
            </Segment>
            <Segment>
              {bottomButtons({
                setStorablePurchaseOrder,
                setConfirmOpen,
                setConfirmModalHeader,
                setConfirmModalContent,
                rows,
                action
              }).element({ id, locationName }, action)}
            </Segment>
          </Segment.Group>
        </Form>
      </PurchaseOrderContext.Provider>
      <Confirm
        open={confirmOpen}
        header={confirmModalHeader}
        content={confirmModalContent}
        onConfirm={e => {
          setConfirmOpen(false)
          save(e)
        }}
        onCancel={e => setConfirmOpen(false)}
      />
      <POAddProductModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        productLoaded={productLoaded}
        btnActionCallback={addProduct}
      />
    </>
  )
}

export default withRouter(VendorPOAddEdit)
