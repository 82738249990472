import FulfillmentApiRequestHandler from './fulfillmentApi/FulfillmentApiRequestHandler'
import McsRequestHandler from './HttpRequestsHandler'
import {
  ENTITY_PREFIX_PRODUCT,
  ENTITY_PREFIX_SNAPSHOT,
  ENTITY_PREFIX_PRICINGZONE
} from './variablesRepository'
const mcsBasePath = '/product'
const fulfillmentBasePath = '/product'

/**
 * @typedef {import('../types/product').Product} Product
 */

const getAll = () => McsRequestHandler.get(mcsBasePath)

const get = id =>
  McsRequestHandler.get(
    `${mcsBasePath}/${encodeURIComponent(ENTITY_PREFIX_PRODUCT)}${id}`
  )

const save = product => McsRequestHandler.post(mcsBasePath, product)

const saveByZone = (id, zoneId, product) =>
  McsRequestHandler.post(
    `${mcsBasePath}/${encodeURIComponent(id)}/${encodeURIComponent(zoneId)}`,
    product
  )

const saveByProduct = (id, product) =>
  McsRequestHandler.post(
    `${mcsBasePath}/${encodeURIComponent(id)}/${encodeURIComponent(id)}`,
    product
  )

const saveProductPropertyByZone = (id, zoneId, updatedProp) => {
  return McsRequestHandler.post(
    `${mcsBasePath}/${encodeURIComponent(id)}/${encodeURIComponent(zoneId)}`,
    updatedProp
  )
}

const getByZoneId = (id, zoneId) =>
  McsRequestHandler.get(
    `${mcsBasePath}/${encodeURIComponent(id)}/${encodeURIComponent(
      ENTITY_PREFIX_SNAPSHOT
    )}${encodeURIComponent(zoneId)}`
  )

/**
 *
 * @param {string} sku
 * @param {string} priceZoneId
 * @returns {Promise<*>}
 */
const getPricingZone = (sku, priceZoneId) =>
  McsRequestHandler.get(
    `${mcsBasePath}/${encodeURIComponent(ENTITY_PREFIX_PRODUCT + sku)}/${encodeURIComponent(
      ENTITY_PREFIX_PRICINGZONE
    )}${encodeURIComponent(priceZoneId)}`
  )

const getHistory = (id, qsParams) =>
  McsRequestHandler.get(`${mcsBasePath}/getHistoryProduct/${id}`, {
    queryStringParameters: qsParams
  })

/**
 * @param {Object} params
 */
const filterBy = params =>
  McsRequestHandler.get(mcsBasePath, {
    queryStringParameters: params
  })

const unassignFromZone = (products, zoneId) =>
  McsRequestHandler.post(`${mcsBasePath}/unassign`, { products, zoneId })

const assignToZone = (products, zoneId) =>
  McsRequestHandler.post(`${mcsBasePath}/assign`, { products, zoneId })

/**
 * @param {string} productNumber
 * @return {Promise<Product>}
 */
const getFullProduct = async productNumber => {
  const basic = McsRequestHandler.get(
    `${mcsBasePath}/${encodeURIComponent(
      ENTITY_PREFIX_PRODUCT
    )}${productNumber}`
  )
  const inventory = FulfillmentApiRequestHandler.post(
    `${fulfillmentBasePath}/getProductInventoryInformation`,
    { productNumber, ignoreFullDemandNotShipped: false }
  )
  const promisesResolved = await Promise.all([basic, inventory])

  const productData = promisesResolved[0]
  productData.inventory = null
  if (promisesResolved[1].success) {
    productData.inventory = promisesResolved[1].inventoryInformation
  }

  return productData
}

const ProductsRepository = {
  get,
  getFullProduct,
  getAll,
  save,
  saveByZone,
  saveProductPropertyByZone,
  filterBy,
  unassignFromZone,
  assignToZone,
  getByZoneId,
  getHistory,
  saveByProduct,
  getPricingZone
}

export default ProductsRepository
