import React from 'react'
import { Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { ItemList } from '../../../../common/list'
import { PRODUCTS_ADD_LINK } from '../../../../../routes/masterLayoutRoutes/ProductsRoutes'

const LogDetail = ({ historyDetail, match }) => {

  return (
    <>
      <Header as='h2' textAlign='center'>Change Log</Header>
      <ItemList
        columns={[
          { key: 'field', name: 'Field', width: 200 },
          { key: 'oldValue', name: 'Old Value', width: 200 },
          { key: 'newValue', name: 'New Value' }
        ]}
        rows={historyDetail}
        rowCount={historyDetail.length}
        totalRows={historyDetail.length}
        showEdit
        totalPages={1}
        addLink={PRODUCTS_ADD_LINK}
        showCheckbox={false}
      />
    </>
  )
}

LogDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired
    })
  }),
  setLoading: PropTypes.func,
  openLog: PropTypes.bool
}

export default LogDetail
