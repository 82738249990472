import React from 'react'

export const withRequest = WrappedComponent => {
  return class extends React.Component {
    state = {
      error: undefined,
      message: undefined,
      loading: false
    }

    setLoading = value => {
      this.setState(prevState => ({
        ...prevState,
        loading: value
      }))
    }

    setSuccess = (values = {}) => {
      this.setState(prevState => ({
        ...prevState,
        error: false,
        message: '',
        ...values
      }))
    }

    setFailure = errObj => {
      this.setState(prevState => ({
        ...prevState,
        error: true,
        message: errObj.message
      }))

      this.setLoading(false)
    }

    render () {
      return (
        <WrappedComponent
          requestState={this.state}
          setLoading={this.setLoading}
          setSuccess={this.setSuccess}
          setFailure={this.setFailure}
          {...this.props}
        />
      )
    }
  }
}
