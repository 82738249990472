import PropTypes from 'prop-types'
import React from 'react'
import { Switch, withRouter } from 'react-router'
import { Link, Route } from 'react-router-dom'
import { Grid, Icon, Menu } from 'semantic-ui-react'
import masterLayoutRoutes from '../../routes/masterLayoutRoutes/MasterLayoutRoutes'

const MasterLayoutWithRouter = props => {
  return (
    <Grid.Row className='sidebar-section'>
      <Grid.Column className='sidebar'>
        <Menu vertical inverted fluid>
          {masterLayoutRoutes.map((route, index) => {
            if (!route.isMenu) return ''
            return (
              <Menu.Item
                as={Link}
                to={route.path}
                active={route.menuMatchingRoutes.includes(
                  props.location.pathname
                )}
                key={index}
              >
                <Icon name={route.icon} />
                {route.pageTitle}
              </Menu.Item>
            )
          })}
        </Menu>
      </Grid.Column>
      <Grid.Column className='content-section'>
        <Switch>
          {masterLayoutRoutes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component {...props} route={route} />
            </Route>
          ))}
        </Switch>
      </Grid.Column>
    </Grid.Row>
  )
}

MasterLayoutWithRouter.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

export const MasterLayout = withRouter(MasterLayoutWithRouter)
