import React from 'react'
import LocationTypeAddEdit from '../../components/modules/master/locationTypes/addEdit/LocationTypeAddEdit'
import LocationTypeList from '../../components/modules/master/locationTypes/list/LocationTypeList'
export const LOCATION_TYPES_LISTING_LINK = '/location-types'
export const LOCATION_TYPES_ADD_LINK = '/location-types/add'
export const LOCATION_TYPES_EDIT_LINK = '/location-types/edit'

const LocationTypeRoutes = [
  {
    path: LOCATION_TYPES_LISTING_LINK,
    pageTitle: 'Location Types',
    icon: 'th',
    isMenu: true,
    menuMatchingRoutes: [LOCATION_TYPES_LISTING_LINK, LOCATION_TYPES_ADD_LINK],
    exact: true,
    breadCrumbs: [
      {
        name: 'Location Types'
      }
    ],
    component: (props) => {
      return (
        <LocationTypeList {...props} />
      )
    }
  },
  {
    path: LOCATION_TYPES_ADD_LINK,
    pageTitle: 'Add Location Type',
    icon: 'th',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Location Types',
        path: LOCATION_TYPES_LISTING_LINK
      },
      {
        name: 'Add'
      }
    ],
    exact: true,
    component: (props) => {
      return (
        <LocationTypeAddEdit {...props} />
      )
    }
  },
  {
    path: LOCATION_TYPES_EDIT_LINK + '/:id',
    pageTitle: 'Edit Location Type',
    icon: 'align center',
    isMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Location Type',
        path: LOCATION_TYPES_LISTING_LINK
      },
      {
        name: 'Edit'
      }
    ],
    exact: true,
    component: (props) => {
      return (
        <LocationTypeAddEdit {...props} />
      )
    }
  }
]

export default LocationTypeRoutes
