import React from 'react'
import LocationItemList from '../../components/modules/location/items/list/LocationItemList'
import ProductConfig from '../../components/modules/location/items/productConfig/ProductConfig'
import NotFound from '../../containers/notFoundPage/NotFound'
import VendorPORoutes from './VendorPORoutes'
import WarehousePORoutes from './WarehousePORoutes'

export const IN_LOCATION_ITEM_LIST_LINK = '/in-location/:locationId/list'
export const IN_LOCATION_VENDOR_PO_LIST =
  '/in-location/:locationId/vendor-po-list'
export const IN_LOCATION_PRODUCT_EDIT_LINK =
  '/in-location/:locationId/products/:productId'
export const IN_LOCATION_VENDOR_DIRECT_PO_LIST_LINK =
  '/in-location/:locationId/vendor-direct'
export const IN_LOCATION_VD_RECEIVE_LINK = '/in-location/:locationId/vd-receive'
export const IN_LOCATION_PO_ADD_EDIT = '/in-location/:locationId/po/:poId'

export const LocationLayoutRoutes = [
  {
    path: IN_LOCATION_ITEM_LIST_LINK,
    pageTitle: "Item's List",
    isLocationMenu: true,
    breadCrumbs: [{ name: 'Item List' }],
    exact: true,
    component: props => <LocationItemList {...props} />
  },
  {
    path: IN_LOCATION_PRODUCT_EDIT_LINK,
    pageTitle: 'Edit Product',
    icon: 'align center',
    isLocationMenu: false,
    menuMatchingRoutes: [],
    breadCrumbs: [
      {
        name: 'Location',
        path: IN_LOCATION_ITEM_LIST_LINK
      },
      {
        name: 'Products',
        path: IN_LOCATION_ITEM_LIST_LINK
      },
      {
        name: 'Product Edit'
      }
    ],
    exact: true,
    component: props => <ProductConfig {...props} />
  },
  ...WarehousePORoutes,
  ...VendorPORoutes,
  {
    path: '/*',
    exact: false,
    pageTitle: '404 - Page Not Found',
    component: props => {
      return <NotFound {...props} />
    }
  }
]
