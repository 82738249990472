import React from 'react'
import ProductList from '../../components/modules/master/products/list'
import ViewProduct from '../../components/modules/master/products/viewProduct/ProductView'

export const PRODUCTS_LISTING_LINK = '/products'
export const PRODUCTS_ADD_LINK = '/products/add'
export const PRODUCTS_VIEW_LINK = '/products/view'

const ProductsRoutes = [
  {
    path: PRODUCTS_LISTING_LINK,
    pageTitle: 'Items',
    icon: 'boxes',
    isMenu: true,
    menuMatchingRoutes: [PRODUCTS_LISTING_LINK, PRODUCTS_ADD_LINK],
    exact: true,
    breadCrumbs: [
      {
        name: 'Items'
      }
    ],
    component: props => {
      return <ProductList {...props} />
    }
  },
  {
    path: PRODUCTS_VIEW_LINK + '/:id',
    pageTitle: 'Items',
    icon: 'boxes',
    isMenu: false,
    menuMatchingRoutes: [],
    exact: true,
    breadCrumbs: [
      {
        name: 'Items',
        path: PRODUCTS_LISTING_LINK
      },
      {
        name: 'View'
      }
    ],
    component: props => {
      return <ViewProduct {...props} />
    }
  }
]

export default ProductsRoutes
