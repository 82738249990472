import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useHistory, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'semantic-ui-react'
import useDataFetchingTransactionsApi from '../../../../hooks/useDataFetchingTransactionsApi'
import useMarshallResponse from '../../../../hooks/useMarshallResponse'
import PurchaseOrdersRepository from '../../../../repositories/transactionsApi/PurchaseOrdersRepository'
import {
  IN_LOCATION_WAREHOUSE_PO,
  IN_LOCATION_WAREHOUSE_PO_ADD,
  IN_LOCATION_WAREHOUSE_PO_LIST_LINK
} from '../../../../routes/locationLayoutRoutes/WarehousePORoutes'
import { BreadCrumb } from '../../../common/breadCrumb/BreadCrumb'
import CustomSimpleCellFormatter from '../../../common/list/CustomSimpleCellFormatter'
import { ItemList } from '../../../common/list/ItemList'
import { ACTIONS } from './constants'

const actionBtnKey = 'actionBtn'

const gridModel = [
  {
    key: 'reference',
    name: 'PO #',
    resizable: true,
    defaultValue: '',
    serverResponseKey: 'reference'
  },
  {
    key: 'status',
    name: 'Status',
    resizable: true,
    defaultValue: '',
    serverResponseKey: 'status'
  },
  {
    key: 'lastUpdate',
    name: 'Date',
    resizable: true,
    formatters: [value => moment(value).format('L')],
    serverResponseKey: 'lastUpdate'
  },
  {
    key: 'type',
    name: 'Type',
    resizable: true,
    defaultValue: '',
    serverResponseKey: 'type'
  },
  {
    key: actionBtnKey,
    width: 75,
    name: 'Action',
    resizable: true,
    formatter: CustomSimpleCellFormatter
  }
]
export const WarehousePOList = props => {
  const history = useHistory()

  const {
    data,
    // errors,
    totalCount,
    page,
    term,
    totalPages,
    isLoading,
    onSearch,
    onPageChange
  } = useDataFetchingTransactionsApi(PurchaseOrdersRepository, {
    vendor: 'warehouse'
  })
  const notifiedRef = useRef(false)

  useEffect(() => {
    const notified = notifiedRef.current
    if (!notified) {
      launchPurchaseOrderNotification()
      notifiedRef.current = true
    }
  })

  const launchPurchaseOrderNotification = () => {
    const notifySuccess = message => toast(message)

    const purchaseOrderNotification =
      props.location.state?.purchaseOrderNotification

    if (
      purchaseOrderNotification &&
      !_.isEmpty(purchaseOrderNotification) &&
      purchaseOrderNotification.success
    ) {
      notifySuccess(purchaseOrderNotification.message)
      history.replace(props.location.pathname, null)
    }
  }

  const appendEditButtons = rows => {
    return rows.map(row => {
      let action
      let btnLabel
      switch (row.status) {
        case 'open':
          btnLabel = ACTIONS.edit
          action = ACTIONS.edit
          break
        case 'closed':
        case 'processing':
          btnLabel = ACTIONS.view
          action = ACTIONS.view
          break
        case 'draft':
          btnLabel = ACTIONS.edit
          action = ACTIONS.draft
          break
        default:
      }

      return {
        ...row,
        [actionBtnKey]: (
          <Button
            primary
            as={Link}
            to={IN_LOCATION_WAREHOUSE_PO.replace(
              ':locationId',
              props.match.params.locationId
            )
              .replace(':action(add|edit|draft|view)', action)
              .replace(':poId', row.id)}
            size='mini'
            content={btnLabel.ucfirst()}
          />
        )
      }
    })
  }

  const rows = appendEditButtons(useMarshallResponse(gridModel, data))

  const topButtons = () => (
    <Button
      primary
      as={Link}
      to={IN_LOCATION_WAREHOUSE_PO_ADD.replace(
        ':locationId',
        props.match.params.locationId
      )}
      content='Create'
    />
  )

  const bottomButtons = () => (
    <Button.Group floated='left'>
      <Button
        as={Link}
        to={IN_LOCATION_WAREHOUSE_PO_LIST_LINK.replace(
          ':locationId',
          props.match.params.locationId
        )}
        labelPosition='left'
        icon='arrow left'
        content='Back'
      />
    </Button.Group>
  )

  return (
    <ItemList
      breadCrumb={<BreadCrumb route={props.route} />}
      columns={gridModel}
      showEdit
      isLoading={isLoading}
      rows={rows}
      rowCount={totalCount}
      totalRows={totalCount}
      onPageChanged={onPageChange}
      activePage={page}
      searchTerm={term}
      totalPages={totalPages}
      onSortChanged={(sortColum, sortDirection) => {
        return []
      }}
      onSearch={onSearch}
      searchBy='reference'
      topButtons={topButtons()}
      bottomButtons={bottomButtons()}
    />
  )
}

export default withRouter(WarehousePOList)
